import React, { useState, useRef } from 'react';
import { ApiDataAccess } from '../infrastructure/ApiDataAccess';
import { DataObjectContainer } from '../dataObjects/DataObjectContextManager';
import { PageableList } from '../dataObjects/BoundControls';
import { Popup } from '../core/Popup';
import { Card } from '../core/Card';
import { Textbox } from '../core/Textbox';
import { MetaDataPopup } from '../components/MetaDataPopup';
import { UploadFilePopup } from '../components/UploadFilePopup';
import { SkuManualEntryPopup } from '../components/SkuManualEntryPopup';

export const SkusView = () => {

    var timeout = null;
    const manualPopup = useRef(null);
    const metaDataPopup = useRef(null);
    const artworkPopup = useRef(null);
    const thumbnailPopup = useRef(null);
    const printFilePopup = useRef(null);
    const context = useRef(null);
    const list = useRef(null);
    const [dataId, setDataId] = useState(null);
    const [filter, setFilter] = React.useState({ ordernumber: null });

    const onEnterManualClick = item => {
        manualPopup.current.show();
    }

    const onMetaDataClick = item => {
        setDataId(item.id);
        metaDataPopup.current.show();
    }

    const onArtworkClick = item => {
        setDataId(item.id);
        artworkPopup.current.show();
    }

    const onThumbnailClick = item => {
        setDataId(item.id);
        thumbnailPopup.current.show();
    }

    const onPrintFileClick = item => {
        setDataId(item.id);
        printFilePopup.current.show();
    }

    const notify = item => {
        console.log(item);
        var dataAccess = new ApiDataAccess('/api/notify');
        dataAccess.post(`/${item.id}`)
            .then(data => {
                list.current.refresh();
            });
    }

    const onFilterTextChange = (value, valid) => {
        window.clearTimeout(timeout);
        if (valid || value.length == 0) {
            timeout = window.setTimeout(() => {
                var f = filter;
                f['ordernumber'] = value ? value : null;
                setFilter(f);
                list.current.refresh();
            }, 500);
        }
    }

    const getSkuItemState = (item) => {
        switch (item.skuState) {
            case 'TRN':
                return 'QUEUED';
            case 'CPL':
                return 'SENT';
            case 'ERR':
                return 'ERROR';
            case 'DPR':
                return 'DEPRECATED';
            default:
                return 'WAITING';
        }
    }

    return (
        <Card title='SKUs' subtitle='The SKU information received '>

            <button className='btn btn-primary' onClick={() => { onEnterManualClick() }}>Enter Manual</button>

            <Popup ref={manualPopup}>
                <SkuManualEntryPopup popup={manualPopup} onCloseClick={() => { list.current.refresh(); }} />
            </Popup>

            <Popup ref={metaDataPopup}>
                <MetaDataPopup popup={metaDataPopup} dataId={dataId} onCloseClick={() => { list.current.refresh(); }} />
            </Popup>

            <Popup className='artwork' ref={artworkPopup}>
                <UploadFilePopup popup={artworkPopup} fileType="Artwork" dataId={dataId} onCloseClick={() => { list.current.refresh(); }} />
            </Popup>

            <Popup className='thumbnail' ref={thumbnailPopup}>
                <UploadFilePopup popup={thumbnailPopup} fileType="Thumbnail" dataId={dataId} onCloseClick={() => { list.current.refresh(); }} />
            </Popup>

            <Popup className='printFile' ref={printFilePopup}>
                <UploadFilePopup popup={printFilePopup} fileType="Printfile" dataId={dataId} onCloseClick={() => { list.current.refresh(); }} />
            </Popup>

            <Textbox onChange={(value, valid) => { onFilterTextChange(value, valid) }} regex='[0-9]*' value='' placeholder='Enter Order Number to filter...' />

            <DataObjectContainer ref={context} dataObjectName={'eskoDataDataObject'} >

                <PageableList pageSize={100} ref={list} filter={filter}
                    onRenderHeaderRow={() => {
                        return (
                            <div className='row caption' >
                                <div className='cell'>Id</div>
                                <div className='cell'>Order</div>
                                <div className='cell'>Sku</div>
                                <div className='cell'>Meta Data</div>
                                <div className='cell'>Artwork</div>
                                <div className='cell'>Thumbnail</div>
                                <div className='cell'>Print File</div>
                                <div className='cell'>Audit</div>
                                <div className='cell'>Status</div>
                            </div>
                        )
                    }}
                    onRenderRow={(key, item, references) => {
                        return (
                            <div className={item.skuState == 'ERR' ? 'row background-error color-white' : 'row'} key={`row${key}`}>
                                <div className='cell'>{item.id}</div>
                                <div className='cell'>{item.orderNumber}</div>
                                <div className='cell'>{item.skuNumber}</div>
                                <div className='cell'>
                                    {
                                        item.metaDataAvailable ?
                                            <div onClick={() => { onMetaDataClick(item) }}>Details</div>
                                            :
                                            ''
                                    }
                                </div>
                                <div className='cell'>
                                    {
                                        item.artworkAvailable ?
                                            <a href={`/api/file/artwork/${item.key}`}>Download</a>
                                            :
                                            <div onClick={() => { onArtworkClick(item) }}>Upload</div>
                                    }
                                </div>
                                <div className='cell'>
                                    {
                                        item.thumbnailAvailable ?
                                            <a href={`/api/file/thumbnail/${item.key}`}>Download</a>
                                            :
                                            <div onClick={() => { onThumbnailClick(item) }}>Upload</div>
                                    }
                                </div>
                                <div className='cell'>
                                    {
                                        item.printFileAvailable ?
                                            <a href={`/api/file/printfile/${item.key}`}>Download</a>
                                            :
                                            <div onClick={() => { onPrintFileClick(item) }}>Upload</div>
                                    }
                                </div>
                                <div className='cell'><a href={`/audit/order/${item.orderNumber}`}>Show</a></div>
                                <div className='cell'>{item.skuState === 'TRN' ? <button className='btn btn-primary btn-sm' onClick={() => { notify(item) }}>Send</button> : getSkuItemState(item)} </div>
                            </div>
                        )
                    }} />

            </DataObjectContainer>
        </Card>
    )
}