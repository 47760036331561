import React, { useState, forwardRef, useImperativeHandle, useRef } from 'react';
import { XSquare } from 'react-feather';

import './Popup.css';

export const Confirmation = forwardRef(({ captionYes, captionNo}, ref) => {

    const [payload, setPayload] = useState(null);
    const popup = useRef(null);


    useImperativeHandle(ref, () => ({

        show(content, title, onConfirmClick, onCancelClick) {
            var p = {
                title: title,
                content: content,
                onConfirmClick: onConfirmClick,
                onCancelClick: onCancelClick
            };
            setPayload(p);
            popup.current.show();
        }
    }));


    const onYesClick = () => {
        popup.current.close();
        if (payload.onConfirmClick) {
            payload.onConfirmClick();
        }
    }

    const onNoClick = () => {
        popup.current.close();
        if (payload.onCancelClick) {
            payload.onCancelClick();
        }
    }
  
    return (
        <Popup ref={popup}>

            {payload && payload.title &&
                <div className='topSection'>
                    <div className='left'></div>
                    <div className='center'><h4>{payload.title}</h4></div>
                    <div className='right'><XSquare className='icon btn-close clickable' onClick={() => { popup.current.close() }} /></div>
                </div>
            }

            {payload && payload.content &&
                <div className='contentSection'>
                    {payload.content}
                </div>
            }

            {payload && (payload.onConfirmClick || payload.onCancelClick) 
                ?
                <div className='footerSection'>
                    <button className='btn btn-modal btn-primary' onClick={() => { onYesClick() }}>{captionYes ? captionYes : 'Yes'}</button>
                    <button className='btn btn-modal btn-secondary pull-right' onClick={() => { onNoClick() }}>{captionNo ? captionNo : 'No'}</button>
                </div>
                :
                <div className='footerSection'>
                    <button className='btn btn-modal-block btn-secondary' onClick={() => { onNoClick() }}>{'Close'}</button>
                </div>
            }

        </Popup>
    )
});

export const Popup = forwardRef(({ children, className, closeOnClickAway, onClose }, ref) => {

    const [visible, setVisible] = useState(false);

    useImperativeHandle(ref, () => ({

        show() {
            //document.body.style.overflow = 'hidden';
            setVisible(true);
        },

        close() {
            //document.body.style.overflow = 'auto';
            setVisible(false);

            if (onClose) {
                setTimeout(() => {
                    onClose();
                }, 100);
            }
        }

    }));

    const closeModal = () => {
        //document.body.style.overflow = 'auto';
        setVisible(false);

        if (onClose) {
            setTimeout(() => {
                onClose();
            }, 100);
        }
    }

    return (
        <div>
            {visible &&
                <div>
                    <div className={closeOnClickAway ? 'backSplash clickable' : 'backSplash'} onClick={() => { if (closeOnClickAway === true) { closeModal() } }} />
                    <div className={className ? `popup noscrollbar ${className}` : 'popup noscrollbar'}>
                        {children}
                    </div>
                </div>
            }
        </div>
    )

});