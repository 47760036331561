import React from 'react';
import { Check, X, AlertTriangle } from 'react-feather';

export const Status = {
    Success: 0,
    Failure: 1,
    Warn: 2
}

export const StatusDisplay = ({ status, hideText }) => {

    const getIcon = () => {
        switch (status) {
            case Status.Success:
                return (hideText ? <Check className='icon' /> : <span><Check className='icon' /> Success</span>)
            case Status.Failure:
                return (hideText ? <X className='icon' /> : <span><X className='icon' /> Failure</span>)
            case Status.Warn:
                return (hideText ? <AlertTriangle className='icon' /> : <span><AlertTriangle className='icon' /> Failure</span>)
            default:
                return (<span> </span>)
        }
    }

    const getClasses = (status) => {
        switch (status) {
            case Status.Success:
                return 'color-valid';                
            case Status.Failure:
                return 'color-error';                
            case Status.Warn:
                return 'color-orange';
            default:
                return 'color-valid';
        }
    }

    return (
        <div className={getClasses(status)}>
            {getIcon()}
        </div>
    )
}