import React, { useState, useContext } from 'react';
import { User, Camera, MessageSquare, Twitter, Info, LogOut, XSquare } from 'react-feather';
import { ApiDataAccess } from '../infrastructure/ApiDataAccess';
import { ToggleContainer, CodeBlock } from '../infrastructure/Containers';
import { CapabilityName } from '../infrastructure/Constants';
import { CapabilitiesContext, SettingsContext, UserContext } from '../infrastructure/Contexts';
import Cookies from 'universal-cookie';
import { useNavigate } from "react-router-dom";

export const Layout = props => {
    return (
        <div id='layout'>
            <Header />
            <div className='container'>
                {props.children}
                <ReleaseNotesModal />
            </div>
        </div>
    );
}


export const Header = props => {

    let navigate = useNavigate();

    return (
        <div className='header background-white'>
            <Colorbar />

            <svg className='logo clickable' viewBox="0 0 460 185" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => { navigate('/') }}>
                <path d="M52.7818 23.9357C59.6609 23.934 66.3626 26.1166 71.9192 30.1684C77.4758 34.2203 81.5997 39.9317 83.6956 46.478H21.8704C23.9662 39.932 28.0898 34.2209 33.6459 30.1691C39.202 26.1173 45.9032 23.9345 52.7818 23.9357ZM80.7572 72.6028C77.2808 78.5493 71.9973 83.2336 65.6743 85.9751C59.3513 88.7166 52.3182 89.3725 45.5966 87.8476C38.875 86.3227 32.8151 82.6964 28.2971 77.4954C23.7792 72.2944 21.0386 65.7897 20.4734 58.9262H105.499C105.499 58.9262 105.566 57.1791 105.566 56.2951C105.566 27.2159 81.9331 3.65002 52.7818 3.65002C23.6306 3.65002 0 27.2182 0 56.2975C0 85.3767 23.6306 108.943 52.7818 108.943C63.9137 108.947 74.7621 105.435 83.776 98.9087C92.79 92.3824 99.5081 83.1758 102.97 72.6051L80.7572 72.6028Z" fill="#00B3E8" />
                <path d="M281.165 88.6522C263.236 88.6522 248.721 74.1661 248.721 56.2951C248.721 38.4242 263.246 23.9357 281.165 23.9357C299.084 23.9357 313.606 38.4265 313.606 56.2951C313.606 74.1638 299.082 88.6522 281.165 88.6522ZM315.278 7.8863V16.1378C305.751 8.06093 293.659 3.63432 281.165 3.65004C252.011 3.65004 228.381 27.2205 228.381 56.2951C228.381 85.3698 252.011 108.94 281.165 108.94C293.66 108.957 305.752 104.529 315.278 96.4501V105.474H337.937V7.8863H315.278Z" fill="#00B3E8" />
                <path d="M424.166 72.305C421.332 77.2717 417.233 81.4004 412.284 84.2716C407.336 87.1428 401.715 88.6542 395.993 88.6522C378.065 88.6522 363.55 74.1661 363.55 56.2951C363.55 38.4242 378.074 23.9381 395.993 23.9381C402.211 23.9378 408.298 25.7238 413.529 29.0833C418.759 32.4428 422.912 37.2341 425.494 42.8861H447.047C444.064 31.6346 437.439 21.6852 428.205 14.5882C418.972 7.49122 407.648 3.6454 395.998 3.65003C366.849 3.65003 343.216 27.2205 343.216 56.2951C343.216 85.3698 366.849 108.94 395.998 108.94C407.184 108.946 418.084 105.401 427.123 98.8164C436.163 92.2318 442.874 82.9481 446.291 72.305H424.166Z" fill="#00B3E8" />
                <path d="M135.455 157.826H127.956V165.305H135.455V157.826Z" fill="#00B3E8" />
                <path d="M120.456 164.805H113.208V172.035H120.456V164.805Z" fill="#00B3E8" />
                <path d="M127.201 164.558H122.202V169.544H127.201V164.558Z" fill="#00B3E8" />
                <path d="M125.208 172.038H120.458V176.774H125.208V172.038Z" fill="#00B3E8" />
                <path d="M118.952 180.761H114.703V185H118.952V180.761Z" fill="#00B3E8" />
                <path d="M156.734 26.6273C161.248 24.6658 166.149 23.7555 171.066 23.965C175.984 24.1746 180.789 25.4987 185.119 27.837C188.975 26.4412 193.567 24.9081 196.943 24.187C200.233 23.4891 205.558 22.9982 210.294 22.6865C205.341 16.7222 199.132 11.9235 192.11 8.6329C185.088 5.3423 177.425 3.64087 169.668 3.65003H169.324C166.481 7.42336 163.037 12.2691 161.2 15.9517C159.836 18.6666 158.213 22.6981 156.734 26.6203" fill="#A7C738" />
                <path d="M113.203 163.06H120.703V157.824H127.951V150.347H135.451V113.57C128.272 117.787 118.391 123.447 113.203 126.409V163.06Z" fill="#6C66A5" />
                <path d="M156.734 26.6273C158.213 22.7097 159.836 18.6735 161.2 15.9587C163.044 12.2761 166.481 7.43034 169.324 3.65701C156.903 3.71877 144.904 8.16868 135.451 16.2193V0H113.203V15.9843L138.117 48.8718C139.278 43.9744 141.564 39.4145 144.796 35.5535C148.027 31.6924 152.113 28.6363 156.732 26.6273" fill="#F8DD01" />
                <path d="M185.12 27.837C189.219 30.0547 192.791 33.1293 195.593 36.8512C198.395 40.5731 200.361 44.8548 201.358 49.4045C205.147 51.7094 208.769 54.2803 212.194 57.0977C215.575 59.908 218.9 63.2207 221.54 66.0169C222.143 62.812 222.448 59.5584 222.453 56.2975C222.469 44.016 218.166 32.1193 210.294 22.6865C205.565 23.0005 200.233 23.4821 196.945 24.1847C193.569 24.9081 188.975 26.4412 185.12 27.837Z" fill="#7AC138" />
                <path d="M221.54 66.0146C218.899 63.223 215.577 59.9079 212.194 57.0977C208.768 54.2803 205.146 51.7094 201.355 49.4045C202.356 53.9237 202.365 58.6059 201.382 63.129C200.399 67.6521 198.447 71.9087 195.66 75.6061C195.939 80.0657 196.196 85.3907 196.196 90.0108C196.196 93.8795 195.66 98.3856 194.992 102.499C201.841 98.7579 207.774 93.5457 212.364 87.2376C216.954 80.9295 220.086 73.6826 221.535 66.0192" fill="#00B3E8" />
                <path d="M195.66 75.6061C192.841 79.3846 189.23 82.5016 185.079 84.7387C180.928 86.9758 176.337 88.279 171.629 88.5569C170.248 90.0062 169.005 91.2694 168.199 92.0068C165.999 94.0191 156.676 100.184 149.395 104.904C156.692 107.933 164.576 109.288 172.467 108.872C180.358 108.455 188.055 106.276 194.992 102.496C195.66 98.3856 196.196 93.8888 196.196 90.0131C196.196 85.3907 195.937 80.0657 195.66 75.6061Z" fill="#6C66A5" />
                <path d="M145.472 77.7952C141.985 73.9341 139.493 69.2819 138.212 64.2416C136.931 59.2013 136.899 53.9249 138.119 48.8695L113.205 15.9843V68.0176C117.396 69.9926 123.18 72.547 128.454 74.2987C133.728 76.0504 140.457 77.1578 145.474 77.7882" fill="#F47745" />
                <path d="M171.629 88.5545C170.977 88.5918 170.332 88.6522 169.671 88.6522C165.098 88.6543 160.577 87.6877 156.406 85.8162C152.235 83.9448 148.508 81.211 145.472 77.7952C140.457 77.1601 133.756 76.0644 128.452 74.3057C123.148 72.547 117.403 69.9973 113.203 68.0246V126.416C118.391 123.454 128.272 117.792 135.453 113.577V96.3617C139.628 99.912 144.331 102.792 149.393 104.897C156.676 100.177 165.997 94.0121 168.197 91.9998C169.005 91.2624 170.248 89.9992 171.629 88.5522" fill="#E15530" />
                <path d="M157.743 137.254H154.923V134.823H157.743V132.334C157.743 129.617 159.372 128.193 162.381 128.193C162.985 128.2 163.585 128.287 164.166 128.451V130.845C163.662 130.689 163.137 130.61 162.609 130.612C161.277 130.612 160.499 131.031 160.499 132.487V134.814H163.745V137.254H160.499V151.554H157.743V137.254Z" fill="#959596" />
                <path d="M170.819 128.454H168.06V151.552H170.819V128.454Z" fill="#959596" />
                <path d="M188.43 141.588C188.3 138.964 186.321 136.863 183.597 136.863C180.71 136.863 178.991 139.031 178.796 141.588H188.43ZM191.089 146.241C190.344 149.93 187.685 151.938 183.92 151.938C178.565 151.938 176.039 148.248 175.878 143.137C175.878 138.124 179.187 134.435 183.76 134.435C189.695 134.435 191.511 139.967 191.35 144.01H178.796C178.7 146.925 180.356 149.512 184.018 149.512C186.291 149.512 187.879 148.409 188.365 146.241H191.089Z" fill="#959596" />
                <path d="M200.403 142.751L194.563 134.823H198.098L202.121 140.679L206.303 134.823H209.614L203.842 142.526L210.326 151.552H206.792L202.121 144.629L197.451 151.552H194.109L200.403 142.751Z" fill="#959596" />
                <path d="M214.548 134.823H217.305V151.552H214.548V134.823ZM217.305 131.817H214.548V128.454H217.305V131.817Z" fill="#959596" />
                <path d="M231.156 136.863C227.459 136.863 226.034 140.034 226.034 143.172C226.034 146.471 227.521 149.514 231.317 149.514C235.112 149.514 236.346 146.311 236.346 143.042C236.346 139.904 234.716 136.863 231.156 136.863ZM223.372 128.451H226.127V137.089H226.192C227.102 135.244 229.242 134.435 231.547 134.435C236.707 134.435 239.268 138.513 239.268 143.235C239.268 147.958 236.737 151.94 231.612 151.94C229.894 151.94 227.421 151.291 226.194 149.319H226.129V151.55H223.372V128.451Z" fill="#959596" />
                <path d="M247.115 128.454H244.358V151.552H247.115V128.454Z" fill="#959596" />
                <path d="M264.736 141.588C264.605 138.964 262.626 136.863 259.902 136.863C257.013 136.863 255.297 139.031 255.103 141.588H264.736ZM267.395 146.241C266.65 149.93 263.991 151.938 260.226 151.938C254.87 151.938 252.344 148.248 252.184 143.137C252.184 138.124 255.492 134.435 260.067 134.435C266 134.435 267.816 139.967 267.653 144.01H255.103C255.006 146.925 256.659 149.512 260.326 149.512C262.594 149.512 264.184 148.409 264.668 146.241H267.395Z" fill="#959596" />
                <path d="M290.783 136.863C287.084 136.863 285.661 140.034 285.661 143.172C285.661 146.471 287.153 149.514 290.949 149.514C294.744 149.514 295.978 146.308 295.978 143.042C295.978 139.904 294.348 136.863 290.788 136.863H290.783ZM283.004 134.823H285.759V137.089H285.824C286.732 135.244 288.874 134.435 291.179 134.435C296.336 134.435 298.898 138.515 298.898 143.235C298.898 147.955 296.369 151.94 291.242 151.94C289.524 151.94 287.051 151.291 285.826 149.319H285.761V157.959H283.007L283.004 134.823Z" fill="#959596" />
                <path d="M314.204 142.914C313.133 143.691 311.061 143.721 309.21 144.045C307.394 144.368 305.836 145.015 305.836 147.055C305.836 148.867 307.394 149.514 309.08 149.514C312.714 149.514 314.202 147.248 314.202 145.729L314.204 142.914ZM318.68 151.487C318.074 151.817 317.389 151.974 316.7 151.938C315.275 151.938 314.372 151.161 314.372 149.351C312.847 151.131 310.805 151.938 308.47 151.938C305.417 151.938 302.921 150.579 302.921 147.248C302.921 143.463 305.745 142.656 308.598 142.102C311.646 141.523 314.242 141.716 314.242 139.646C314.242 137.252 312.265 136.854 310.516 136.854C308.188 136.854 306.46 137.566 306.325 140.025H303.571C303.731 135.884 306.945 134.428 310.675 134.428C313.701 134.428 316.961 135.107 316.961 139.022V147.63C316.961 148.925 316.961 149.509 317.837 149.509C318.125 149.507 318.411 149.452 318.68 149.346V151.487Z" fill="#959596" />
                <path d="M334.317 140.195C333.895 138.101 332.436 136.861 330.165 136.861C326.177 136.861 324.945 139.999 324.945 143.398C324.945 146.471 326.342 149.514 329.835 149.514C332.491 149.514 334.084 147.96 334.438 145.436H337.26C336.645 149.514 334.084 151.95 329.867 151.95C324.745 151.95 322.016 148.39 322.016 143.407C322.016 138.424 324.612 134.444 329.933 134.444C333.728 134.444 336.776 136.226 337.164 140.204L334.317 140.195Z" fill="#959596" />
                <path d="M342.201 128.451H344.957V142.137L352.613 134.823H356.313L349.661 140.907L356.797 151.55H353.295L347.554 142.816L344.957 145.21V151.55H342.201V128.451Z" fill="#959596" />
                <path d="M370.944 142.914C369.876 143.691 367.799 143.721 365.95 144.045C364.101 144.368 362.576 145.015 362.576 147.055C362.576 148.867 364.132 149.514 365.82 149.514C369.452 149.514 370.942 147.248 370.942 145.729L370.944 142.914ZM375.422 151.487C374.816 151.817 374.13 151.973 373.44 151.938C372.015 151.938 371.112 151.161 371.112 149.351C369.587 151.131 367.543 151.938 365.21 151.938C362.157 151.938 359.663 150.579 359.663 147.248C359.663 143.463 362.485 142.656 365.338 142.102C368.386 141.523 370.982 141.716 370.982 139.646C370.982 137.252 369.003 136.854 367.256 136.854C364.928 136.854 363.203 137.566 363.065 140.025H360.306C360.469 135.884 363.682 134.428 367.412 134.428C370.439 134.428 373.699 135.107 373.699 139.022V147.63C373.699 148.925 373.699 149.509 374.574 149.509C374.864 149.507 375.15 149.451 375.42 149.346L375.422 151.487Z" fill="#959596" />
                <path d="M391.413 142.837C391.413 139.895 390.116 136.854 386.645 136.854C383.11 136.854 381.683 139.732 381.683 142.937C381.683 145.943 382.787 149.307 386.419 149.307C390.051 149.307 391.413 145.978 391.413 142.837ZM394.14 150.119C394.14 155.553 391.641 158.335 386.354 158.335C383.208 158.335 379.576 157.072 379.413 153.418H382.172C382.302 155.423 384.7 156.137 386.514 156.137C390.116 156.137 391.544 153.548 391.544 149.793V148.695H391.478C390.57 150.733 388.365 151.736 386.256 151.736C381.264 151.736 378.763 147.823 378.763 143.261C378.763 139.306 380.712 134.421 386.514 134.421C388.626 134.421 390.505 135.351 391.513 137.212H391.544V134.825H394.14V150.119Z" fill="#959596" />
                <path d="M400.142 134.823H402.899V151.552H400.142V134.823ZM402.899 131.817H400.142V128.454H402.899V131.817Z" fill="#959596" />
                <path d="M408.862 134.823H411.458V137.487H411.521C412.685 135.416 414.569 134.444 416.969 134.444C421.393 134.444 422.746 136.97 422.746 140.56V151.561H419.987V140.227C419.987 138.189 418.69 136.863 416.581 136.863C413.242 136.863 411.616 139.094 411.616 142.105V151.552H408.862V134.823Z" fill="#959596" />
                <path d="M440.337 142.837C440.337 139.895 439.038 136.854 435.566 136.854C432.032 136.854 430.604 139.732 430.604 142.937C430.604 145.943 431.708 149.307 435.343 149.307C438.977 149.307 440.337 145.978 440.337 142.837ZM443.061 150.119C443.061 155.553 440.565 158.335 435.277 158.335C432.132 158.335 428.499 157.072 428.336 153.418H431.093C431.224 155.423 433.624 156.137 435.438 156.137C439.038 156.137 440.467 153.548 440.467 149.793V148.695H440.402C439.492 150.733 437.287 151.736 435.18 151.736C430.185 151.736 427.687 147.823 427.687 143.261C427.687 139.306 429.631 134.421 435.438 134.421C437.548 134.421 439.427 135.351 440.435 137.212H440.467V134.825H443.061V150.119Z" fill="#959596" />
                <path d="M460 153.434C459.972 154.737 459.434 155.977 458.501 156.889C457.569 157.801 456.316 158.312 455.011 158.312C453.707 158.312 452.454 157.801 451.522 156.889C450.589 155.977 450.051 154.737 450.023 153.434C450.023 150.742 452.244 148.583 455.027 148.583C455.673 148.572 456.315 148.689 456.915 148.928C457.516 149.166 458.063 149.521 458.526 149.972C458.988 150.423 459.357 150.961 459.61 151.556C459.863 152.15 459.995 152.788 460 153.434ZM451.266 153.434C451.266 155.593 452.866 157.31 455.057 157.31C457.187 157.31 458.757 155.593 458.757 153.464C458.757 151.336 457.187 149.558 455.031 149.558C452.875 149.558 451.266 151.305 451.266 153.434ZM454.265 155.979H453.141V151.126C453.756 151.019 454.379 150.97 455.003 150.98C455.921 150.98 456.335 151.126 456.691 151.333C456.843 151.465 456.964 151.629 457.045 151.813C457.125 151.998 457.164 152.198 457.157 152.399C457.157 152.931 456.743 153.345 456.151 153.522V153.583C456.617 153.76 456.892 154.115 457.038 154.765C457.187 155.504 457.271 155.8 457.395 155.979H456.181C456.032 155.8 455.949 155.358 455.795 154.795C455.706 154.262 455.411 154.025 454.789 154.025H454.265V155.979ZM454.295 153.227H454.829C455.45 153.227 455.953 153.02 455.953 152.529C455.953 152.085 455.627 151.789 454.917 151.789C454.708 151.785 454.5 151.805 454.295 151.847V153.227Z" fill="#959596" />
            </svg>

            <DropdownMenu />
        </div>
    );
}


export const Colorbar = props => {
    return (
        <div className='colorbar'>
            <div className='rectangle background-navy' />
            <div className='rectangle background-orange' />
            <div className='rectangle background-green' />
            <div className='rectangle background-blue' />
            <div className='rectangle background-purple' />
            <div className='rectangle background-yellow' />
        </div>
    );
}


export const DropdownMenu = props => {
    const cookies = new Cookies();
    const capabilitiesContext = useContext(CapabilitiesContext);
    const settingsContext = useContext(SettingsContext);
    const userContext = useContext(UserContext);
    const loader = capabilitiesContext.getCapability(CapabilityName.Loader)
    const modal = capabilitiesContext.getCapability(CapabilityName.Modal)
    const [visible, setVisible] = useState(false);

    const getUserName = () => {

        return `${userContext.firstName} ${userContext.lastName}`;
    }

    const onMyProfileClick = () => {
        setVisible(false);
        loader.show();
        var dataAccess = new ApiDataAccess('api/application');
        dataAccess.get('/userDetails')
            .then(profile => {
                console.log('PROFILE', profile);
                loader.hide();
                modal.show(
                    modal => { return (<MyProfileModal modal={modal} profile={profile} capabilitiesContext={capabilitiesContext} settingsContext={settingsContext} />); },
                    modal => { return (<div className='close' onClick={() => { modal.hide() }}>Close</div>); },
                    modal => { return (<h2>My Profile</h2>); }
                );
            });
    }

    const onHelpClick = () => {
        const clientId = settingsContext['Security:ClientId'];
        setVisible(false);
        loader.show();
        var dataAccess = new ApiDataAccess('api/application');
        dataAccess.get(`/cms/help-${clientId}`)
            .then(article => {
                loader.hide();
                modal.show(
                    modal => { return (<div className='article' dangerouslySetInnerHTML={{ __html: article.html }} />); },
                    modal => { return (<div className='close' onClick={() => { modal.hide() }}>Close</div>); },
                    modal => { return (<h2>{article.name}</h2>); }
                );
            });
    }

    const onReleaseNotesClick = () => {
        const version = settingsContext['Application:Version'];
        const clientId = settingsContext['Security:ClientId'];
        setVisible(false);
        loader.show();
        var dataAccess = new ApiDataAccess('api/application');
        dataAccess.get(`/cms/release_${clientId}`)
            .then(article => {
                loader.hide();
                modal.show(
                    modal => { return (<div className='article' dangerouslySetInnerHTML={{ __html: article.html }} />); },
                    modal => { return (<div className='close' onClick={() => { modal.hide() }}>Close</div>); },
                    modal => { return (<h2>Release Notes {version}</h2>); }
                );
            });
    }

    return (
        <div className='contact-pop-over'>
            <button className='btn btn-primary' onClick={() => { setVisible(!visible) }}><User className='icon' /><span className='caption'>{getUserName()}</span></button>
            {visible &&
                <div>
                    <div className='backSplash clear' onClick={() => { setVisible(false); }} />
                    <div className='pop-over'>
                        <div className='triangle'></div>
                        <div className='menu'>
                            <div className='menu-item' onClick={() => { onMyProfileClick() }}><Camera className='icon' /><span>My Profile</span></div>
                            <div className='menu-item'><MessageSquare className='icon' /><span>Feedback</span></div>
                            <div className='menu-separator' />
                            <div className='menu-item' onClick={() => { onReleaseNotesClick() }}><Twitter className='icon' /><span>Release Notes</span></div>
                            <div className='menu-item' onClick={() => { onHelpClick() }} ><Info className='icon' /><span>Help</span></div>
                            <div className='menu-separator' />
                            <div className='menu-item' onClick={() => { document.location = '/account/signout' }}><LogOut className='icon' /><span>Logout</span></div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export const MyProfileModal = props => {

    const [token, setToken] = useState(null);

    const settings = props.settingsContext;
    const notifications = props.capabilitiesContext.getCapability(CapabilityName.Notification);

    const refreshSecurityToken = () => {
        setToken(null);

        var dataAccess = new ApiDataAccess('token');
        dataAccess.get('/')
            .then(token => {
                notifications.add('info', 'Token', 'Access Token has been retrieved');
                setToken(token);
            })
    }

    return (
        <div>
            <div className="row">
                <div className="column w2"><label>First Name</label></div>
                <div className="column w2">{props.profile.user.firstName}</div>
            </div>
            <div className="row">
                <div className="column w2"><label>Last Name</label></div>
                <div className="column w2">{props.profile.user.lastName}</div>
            </div>
            <div className="row">
                <div className="column w2"><label>eMail</label></div>
                <div className="column w2">{props.profile.user.email}</div>
            </div>
            <div className="row">
                <div className="column w2"><label>Locations</label></div>
                <div className="column w2">
                    {props.profile.location.locationName}
                    {props.profile.locations && props.profile.locations.length > 1 &&
                        <ToggleContainer title={'more...'}>
                            {props.profile.locations.map((location, idx) => {
                                if (location.locationId !== props.profile.location.locationId) {
                                    return (
                                        <p key={'loc' + idx}>{location.locationName}</p>
                                    )
                                }
                                else {
                                    return '';
                                }
                            })}
                        </ToggleContainer>
                    }
                </div>
            </div>
            <div className="row">
                <div className="column w2"><label>Role</label></div>
                <div className="column w2">{props.profile.role}</div>
            </div>
            <div className="row">
                <div className="column w2"><label>Type</label></div>
                <div className="column w2">{props.profile.userType}</div>
            </div>
            <div className="row">
                <div className="column w2"><label>Health Check</label></div>
                <div className="column w2"><a href={settings["Health:Url"]} rel="noreferrer" target='_blank'>click here</a></div>
            </div>
            <div className="row">
                <div className="column w2"><label>Security Token</label></div>
                <div className="column w2">
                    <a onClick={() => { refreshSecurityToken() }}>Refresh...</a>
                    {token &&
                        <div>
                            <CodeBlock onCopyToClipboard={() => { return token.access_token; }}>{token.access_token}</CodeBlock>
                            <CodeBlock>Valid From: {token.valid_from}</CodeBlock>
                            <CodeBlock>Valid To: {token.valid_to}</CodeBlock>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}


export const ReleaseNotesModal = props => {
    const settingsContext = useContext(SettingsContext);
    const cookies = new Cookies();
    const currentVersion = cookies.get('version');
    const [visible, setVisible] = useState(false);
    const [article, setArticle] = useState(null);
    const version = settingsContext['Application:Version'];
    const clientId = settingsContext['Security:ClientId'];

    if (version !== currentVersion && !visible) {
        cookies.set('version', version);
        var dataAccess = new ApiDataAccess('api/application');
        dataAccess.get(`/cms/release_${clientId}`)
            .then(data => {
                setArticle(data);
                setVisible(true);
            });
    }
    else {
        console.log('User is aware of current release', currentVersion);
    }

    const hide = () => {
        setVisible(false);
    }

    return (
        <div>
            {visible &&
                <div>
                    <div className='backSplash' />
                    <div className='modal'>
                        <div className='header'>
                            <h2>Release Notes</h2>
                            <XSquare className='icon btn-close' onClick={() => { hide(); }} />
                        </div>

                        <div className='content'>
                            <div className='article' dangerouslySetInnerHTML={{ __html: article.html }} />
                        </div>

                        <div className='footer'>
                            <div className='close' onClick={() => { hide() }}>Close</div>
                        </div>

                    </div>
                </div>
            }
        </div>
    )
}
