import React, { useState, useContext, useEffect } from 'react';
import { XSquare } from 'react-feather';
import { ApiDataAccess } from '../infrastructure/ApiDataAccess';
import { CapabilityName } from '../infrastructure/Constants';
import { CapabilitiesContext } from '../infrastructure/Contexts';

export const MetaDataPopup = ({ popup, dataId }) => {

    const capabilities = useContext(CapabilitiesContext);
    const loader = capabilities.getCapability(CapabilityName.Loader);
    const [metaData, setMetaData] = useState(null);

    useEffect(() => {
        const init = async () => {
            loader.show();
            var dataAccess = new ApiDataAccess('/api/sku');
            dataAccess.get(`/${dataId}`)
                .then(data => {
                    console.log(data.metaData);

                    setMetaData(data.metaData);
                    loader.hide();
                })
                .catch(() => {
                    loader.hide();
                })
        }
        init();
    }, []);


    return (
        <div className='metaDataPopupContent'>
            <div className='topSection'>
                <div className='left'></div>
                <div className='center'><h4>Meta Data</h4></div>
                <div className='right'><XSquare className='icon btn-close clickable' onClick={() => { popup.current.close() }} /></div>
            </div>

            {metaData &&
                <div className='contentSection'>
                    <table className='property'>
                        <thead>
                            <tr>
                                <th>Property</th>
                                <th>Dynamics</th>
                                <th>Domain</th>
                                <th>Dependency</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(metaData).map((key, idx) => {
                                return (
                                    <tr key={`row${idx}`}>
                                        <td>{key}</td>
                                        <td>{metaData[key].dynamicsValue}</td>
                                        <td>{metaData[key].domainValue} - {metaData[key].domainDescription ? metaData[key].domainDescription : 'N/A'}</td>
                                        <td>
                                            {metaData[key].dependentProperties && Object.keys(metaData[key].dependentProperties).length > 0 &&
                                                <table className='dependentProperty'>
                                                    <thead>
                                                        <tr>
                                                            <th>Property</th>
                                                            <th>Dynamics</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {Object.keys(metaData[key].dependentProperties).map((key2, idx2) => {
                                                            return (
                                                                <tr key={`row2${idx2}`}>
                                                                    <td>{key2}</td>
                                                                    <td>{metaData[key].dependentProperties[key2].dynamicsValue}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            }
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            }

            <div className='footerSection'>
                <button className='btn btn-primary btn-modal-block' onClick={() => { popup.current.close(); }}>Cancel</button>
            </div>
        </div>
    )
}