import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router';
import { ApiDataAccess } from '../infrastructure/ApiDataAccess';
import { DataObjectContainer } from '../dataObjects/DataObjectContextManager';
import { PageableList } from '../dataObjects/BoundControls';
import { Card } from '../core/Card';
import { Textbox } from '../core/Textbox';


export const AuditView = () => {

    var timeout = null;
    const { order } = useParams();
    const context = useRef(null);
    const list = useRef(null);
    const [filter, setFilter] = React.useState({ ordernumber: order ? order : null });


    const onFilterTextChange = (value, valid) => {
        window.clearTimeout(timeout);
        if (valid || value.length == 0) {
            timeout = window.setTimeout(() => {
                var f = filter;
                f['ordernumber'] = value ? value : null;
                setFilter(f);
                list.current.refresh();
            }, 500);
        }
    }

    return (
        <Card title='Audit'>

            <Textbox onChange={(value, valid) => { onFilterTextChange(value, valid) }} regex='[0-9]*' value='' placeholder='Enter Order Number to filter...' />

            <DataObjectContainer ref={context} dataObjectName={'auditDataObject'} >

                <PageableList pageSize={100} ref={list} filter={filter}
                    onRenderHeaderRow={() => {
                        return (
                            <div className='row caption' >
                                <div className='cell'>Id</div>
                                <div className='cell'>Order</div>
                                <div className='cell'>Sku</div>
                                <div className='cell'>Activity</div>
                                <div className='cell'>Status</div>
                                <div className='cell'>User</div>
                                <div className='cell'>Date</div>
                            </div>
                        )
                    }}
                    onRenderRow={(key, item, references) => {
                        return (
                            <div className={item.responseStatus == 'Failed' ? 'row clickable background-error color-white' : 'row clickable'}  key={`row${key}`} onClick={() => { document.location =`/audit/${item.id}` }}>
                                <div className='cell'>{item.id}</div>
                                <div className='cell'>{item.orderNumber > 0 ? item.orderNumber : 'N/A' }</div>
                                <div className='cell'>{item.skuNumber > 0 ? item.skuNumber : 'N/A'}</div>
                                <div className='cell'>{item.activity}</div>
                                <div className='cell'>{item.responseStatus}</div>
                                <div className='cell'>{item.createUser}</div>
                                <div className='cell'>{item.createDate}</div>
                            </div>
                        )
                    }} />

            </DataObjectContainer>
        </Card>
    )
}




export const AuditDetailsView = () => {

    const { id } = useParams();
    const [audit, setAudit] = useState(null);

    useEffect(() => {
        const init = async () => {
            var dataAccess = new ApiDataAccess('/api/dataobjects/AuditDataObject');
            dataAccess.get(`/${id}`)
                .then(data => {

                    console.log(data.value.value.dataObject);
                    setAudit(data.value.value.dataObject);
                })
                .catch(() => {
                })
        }
        init();
    }, []);

    return (
        <div>
            {audit &&
                <div>
                    <Card title={`Audit ${id}`}>
                        <div className='audit'>
                            <table>
                                <tbody>
                                    <tr>
                                        <td className='label'>Order Number</td>
                                        <td className='content'>{audit.orderNumber > 0 ? audit.orderNumber : 'N/A'}</td>
                                    </tr>
                                    <tr>
                                        <td className='label'>Sku Number</td>
                                        <td className='content'>{audit.skuNumber > 0 ? audit.skuNumber : 'N/A'}</td>
                                    </tr>
                                    <tr>
                                        <td className='label'>Activity</td>
                                        <td className='content'>{audit.activity}</td>
                                    </tr>
                                    <tr>
                                        <td className='label'>Status</td>
                                        <td className='content'>{audit.responseStatus}</td>
                                    </tr>
                                    <tr>
                                        <td className='label'>Date</td>
                                        <td className='content'>{audit.createDate}</td>
                                    </tr>
                                    <tr>
                                        <td className='label'>Date</td>
                                        <td className='content'>{audit.createUser}</td>
                                    </tr>
                                </tbody>
                            </table> 
                        </div>
                    </Card>


                    <Card title='Request / Payload'>
                        <div className='audit'>
                            <pre>{audit.request}</pre>
                        </div>
                    </Card>

                    <Card title='Response'>
                        <div className='audit'>
                            <pre>{audit.response}</pre>
                        </div>
                    </Card>


                </div>
            }
        </div>
    )
}
