import React, { useState, useContext, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { UploadCloud, Check, X } from 'react-feather';
import { ApiDataAccess } from './ApiDataAccess';

import "./FileUpload.css";


export const FileUpload = props => {

    var progressIndicator = useRef(null);

    const [fileName, setFileName] = useState(props.existingFileName);
    const [progress, setProgress] = useState(0);
    const [errorOnUpload, setErrorOnUpload] = useState(false);

    const [label, setLabel] = useState(props.existingFileName ? props.existingFileName : props.defaultLabel);
    const [uploading, setUploading] = useState(false);
    const [uploadErrorText, setUploadErrorText] = useState(null);
    const MAX_UPLOAD_SIZE = 314572800;

    useEffect(() => {
        if (props.existingFileName) {
            progressIndicator.current.setValue(100);
        }
        else {
            progressIndicator.current.setValue(0);
        }

    }, []);

    const onClickUpload = () => {
        let element = document.getElementById("upload_" + props.id);
        element.click();

        if (props.onClick) {
            props.onClick();
        }
    }

    const onHandleUpload = (e) => {
        e.preventDefault();

        var fileToUpload = e.target.files[0];
        let file = fileToUpload;
        let fileName = fileToUpload ? fileToUpload.name : '';
        let ext = fileName.toLowerCase().split(".").slice(-1)[0];

        if ((fileToUpload.size > MAX_UPLOAD_SIZE) || (ext !== 'pdf' && ext !== 'ai' && ext !== 'jpg' && ext !== 'png')) {
            setErrorOnUpload(true);
            notifyUserOfUploadError('File cannot exceed 300MB and must be in PDF or AI format to upload.');
            notifyArtworkResourceUploadFailed(props.Id);
            return;
        }

        setFileName(fileName);

        let formData = new FormData();

        formData.append("orderNumber", props.orderNumber);
        formData.append("skuNumber", props.skuNumber);
        formData.append("formFile", file);
        formData.append("fileName", fileName);

        try {
            setErrorOnUpload(false);
            setUploading(true);

            let xhr = new XMLHttpRequest();

            xhr.upload.onprogress = (e) => {
                if (e.lengthComputable) {
                    var percentComplete = (e.loaded / e.total) * 99;
                    progressIndicator.current.setValue(percentComplete);
                }
            }

            xhr.upload.onerror = (e) => {
                console.log('Upload Error', e);
                notifyUserOfUploadError('Unknown error uploading file. Please try again later');
                notifyArtworkResourceUploadFailed(props.Id);
                setFileName(props.existingFileName);
            }

            //https://stackoverflow.com/questions/15418608/xmlhttprequest-level-2-determinate-if-upload-finished
            xhr.upload.onload = (e) => {
                console.log("upload finished", xhr);
                setLabel(fileToUpload.name);
                setFileName(fileToUpload.name);
                progressIndicator.current.setWait(true);
                setUploading(false);
            };

            xhr.onreadystatechange = (e) => {

                if (progressIndicator.current) {
                    progressIndicator.current.setValue(99);
                }

                if (xhr.readyState === XMLHttpRequest.DONE) {
                    const status = xhr.status;
                    console.log('Status', status);

                    if (status === 0 || (status >= 200 && status < 400)) {
                        if (progressIndicator.current) {
                            progressIndicator.current.setWait(false);
                            progressIndicator.current.setValue(100);

                            if (props.onUploadComplete) {
                                props.onUploadComplete(props.id, fileToUpload);
                            }

                        }
                    } else {
                        setErrorOnUpload(true);
                        if (progressIndicator.current) {
                            progressIndicator.current.setWait(false);
                        }
                        notifyArtworkResourceUploadFailed(props.Id);
                    }
                }
            }

            console.log(`uploading to ${props.url}...`);

            var dataAccess = new ApiDataAccess('/token');
            dataAccess.get('/epac')
                .then(token => {
                    xhr.open("POST", props.url);
                    xhr.setRequestHeader('Authorization', token);
                    xhr.send(formData);
                });
        }
        catch (ex) {
            console.log('Upload Error', ex);
            notifyUserOfUploadError('Unknown error uploading file. Please try again later');
            setUploading(false);
            setErrorOnUpload(true);
            notifyArtworkResourceUploadFailed(props.Id);
        }
    }

    const onDropUpload = (file) => {
        if (file.size > MAX_UPLOAD_SIZE) {
            setErrorOnUpload(true);
            notifyUserOfUploadError('File cannot exceed 300MB.');
            notifyArtworkResourceUploadFailed(props.Id);
            return;
        }

        let formData = new FormData();

        formData.append("formFile", file);
        formData.append("fileName", file.name)

        try {
            setErrorOnUpload(false);
            setUploading(true);

            let xhr = new XMLHttpRequest();

            xhr.upload.onprogress = (e) => {
                if (e.lengthComputable) {
                    var percentComplete = (e.loaded / e.total) * 99;
                    progressIndicator.current.setValue(percentComplete);
                }
            }

            xhr.upload.onerror = (e) => {
                console.log('Upload Error', e);
                notifyUserOfUploadError('Unknown error uploading file. Please try again later');
                notifyArtworkResourceUploadFailed(props.Id);
                setFileName(props.existingFileName);
            }

            //https://stackoverflow.com/questions/15418608/xmlhttprequest-level-2-determinate-if-upload-finished
            xhr.upload.onload = (e) => {
                console.log("upload finished", xhr);
                setLabel(file.name);
                setFileName(file.name);

                if (props.onUploadComplete) {
                    props.onUploadComplete(props.id, file);
                }
                progressIndicator.current.setWait(true);
                setUploading(false);
            };

            xhr.onreadystatechange = (e) => {

                if (progressIndicator.current) {
                    progressIndicator.current.setValue(99);
                }

                if (xhr.readyState === XMLHttpRequest.DONE) {
                    const status = xhr.status;
                    console.log('Status', status);

                    if (status === 0 || (status >= 200 && status < 400)) {
                        if (progressIndicator.current) {
                            progressIndicator.current.setWait(false);
                            progressIndicator.current.setValue(100);
                        }
                    } else {
                        setErrorOnUpload(true);
                        if (progressIndicator.current) {
                            progressIndicator.current.setWait(false);
                        }
                        notifyArtworkResourceUploadFailed(props.Id);
                    }
                }
            }

            console.log(`uploading to ${props.url}...`);

            xhr.open("POST", props.url);
            xhr.send(formData);
        }
        catch (ex) {
            console.log('Upload Error', ex);
            notifyUserOfUploadError('Unknown error uploading file. Please try again later');
            setUploading(false);
            setErrorOnUpload(true);
            notifyArtworkResourceUploadFailed(props.Id);
        }
    }

    const notifyArtworkResourceUploadFailed = (artworkResourceId) => {
        if (props.onArtworkResourceUploadFailed) {
            props.onArtworkResourceUploadFailed(artworkResourceId);
        }
    }

    const notifyUserOfUploadError = (message) => {

        if (!message) {
            message = 'Unknown error uploading file. Please try again later';
        }

        setUploadErrorText(message);
        setLabel(props.defaultLabel);
        setErrorOnUpload(true);
    }

    const checkSuffixPDFAI = (filename) => {
        let ext = filename.toLowerCase().split(".").slice(-1)[0];
        if (ext === 'ai' || ext === 'pdf') {
            return true;
        } else {
            return false;
        }
    }


    return (
        <div className="dragAndDropArea">
            <div className="uploadTile uploadLink buttonTile">
                <div className="header"></div>
                <div className="content">
                    <h4>{props.sku}</h4>
                    {errorOnUpload
                        ? <div className='progress indicator failure'>
                            <div className='circle'>
                                <X className='icon' />
                            </div>
                        </div>
                        : <ProgressIndicator ref={progressIndicator} size={80} total={100} />
                    }

                    {fileName &&
                        <h4 className='fileName'>{fileName}</h4>
                    }

                    <input className='upload' type="file" id={"upload_" + props.id} accept=".pdf,.ai" onChange={onHandleUpload} disabled={props.disabled} />

                </div>
                <div className="footer">
                    <button className="btn btn-secondary" onClick={() => { onClickUpload() }}>
                        {fileName
                            ? <span>Change</span>
                            : <span>Upload</span>
                        }
                    </button>
                    {errorOnUpload && <p className='tile-error'>{uploadErrorText}</p>}
                </div>

            </div>
        </div>
    );
}


export const ProgressIndicator = forwardRef((props, ref) => {

    const total = props.total;
    const [circumference, setCircumference] = useState(0);
    const [progressOffset, setProgressOffset] = useState(0);
    const [progress, setProgress] = useState(0);
    const [waiting, setWaiting] = useState(false);

    const size = props.size;
    const strokeWidth = 8;
    const center = size / 2;
    const radius = size / 2 - strokeWidth / 2;
    const circleOneStroke = '#EEEEEE';
    const circleTwoStroke = '#7CBD42';


    const style = {
        width: size,
        height: size,
        lineHeight: '80px'
    }

    const containerStyle = {
        height: size,
        lineHeight: '80px'
    }

    const setValue = (step) => {
        var p = (100 / total) * step;
        var c = 2 * Math.PI * radius;
        var po = ((100 - p) / 100) * c;

        setProgress(p);
        setCircumference(c)
        setProgressOffset(po);
    }

    const setWait = (value) => {
        setWaiting(value);
    }

    useImperativeHandle(ref, () => ({
        setValue: (value) => setValue(value),
        setWait: (value) => setWait(value)
    }));

    useEffect(() => {
        setValue(props.value ? props.value : 0);
    }, []);

    const getProgressIcon = (progress) => {

        if (waiting) {
            return <span className="spinner"></span>
        }
        else {
            var value = progress.toFixed();
            if (value == 0) {
                return <span><UploadCloud className='icon thin' /></span>
            }
            else if (value < 100) {
                return <span>{progress.toFixed(0)}%</span>
            }
            else {
                return <span><Check className='icon' /></span>
            }
        }
    }

    return (
        <div className='progress indicator' style={containerStyle}>
            <div className="progress-ring" style={style}>
                <svg width={size} height={size}>
                    <circle
                        className="svg-circle-bg"
                        stroke={circleOneStroke}
                        cx={center}
                        cy={center}
                        r={radius}
                        strokeWidth={strokeWidth}
                    />
                    <circle
                        className="svg-circle"
                        stroke={circleTwoStroke}
                        cx={center}
                        cy={center}
                        r={radius}
                        strokeWidth={strokeWidth}
                        strokeDasharray={circumference}
                        strokeDashoffset={progressOffset}
                        fill='#FFF'
                    />
                </svg>

                <div className='value'>
                    {getProgressIcon(progress)}
                </div>
            </div>

        </div>
    )
});