import React, { useState, useRef, useContext } from 'react';
import { DataObjectContainer } from '../dataObjects/DataObjectContextManager';
import { PageableList, BoundTextbox, BoundDropdownList } from '../dataObjects/BoundControls';
import { Popup, Confirmation } from '../core/Popup';
import { XSquare, Trash, Edit } from 'react-feather';
import { Card } from '../core/Card';
import { CapabilitiesContext } from '../infrastructure/Contexts';
import { CapabilityName } from '../infrastructure/Constants';


export const FeatureView = () => {

    const manualPopup = useRef(null);
    const confirmation = useRef(null);
    const context = useRef(null);
    const list = useRef(null);
    const [dataId, setDataId] = useState(null);

    const onEnterManualClick = item => {
        manualPopup.current.show();
    }

    const onEditClick = async (item) => {
        setDataId(item.id);
        manualPopup.current.show();
    }

    const onDeleteClick = async (item) => {
        confirmation.current.show('Are you sure to delete this Feature Definition?', 'Delete', async () => {
            try {
                await context.current.deleteAsync(item.id);
            }
            catch (ex) {
                confirmation.current.show('This Feature can not be deleted.', 'Delete');
            }
            list.current.refresh();
        });
    }

    return (
        <Card title='Features' subtitle='Define Features here'>

            <button className='btn btn-primary' onClick={() => { onEnterManualClick() }}>New</button>

            <Popup ref={manualPopup}>
                <ManualDataPopupContent popup={manualPopup} dataId={dataId} onSaved={() => { list.current.refresh(); }} />
            </Popup>

            <Confirmation ref={confirmation} />

            <DataObjectContainer ref={context} dataObjectName={'featureDataObject'} >

                <PageableList pageSize={100} ref={list} 
                    onRenderHeaderRow={() => {
                        return (
                            <div className='row caption' >
                                <div className='cell'>Id</div>
                                <div className='cell'>Name</div>
                                <div className='cell'>Dynamics Property</div>
                                <div className='cell'>Domain Property</div>
                                <div className='cell'>Dynamics Bridge Path</div>
                                <div className='cell small'>&nbsp;</div>
                                <div className='cell small'>&nbsp;</div>
                            </div>
                        )
                    }}
                    onRenderRow={(key, item, references) => {
                        return (
                            <div className='row' key={`row${key}`}>
                                <div className='cell'>{item.id}</div>
                                <div className='cell'>{item.name}</div>
                                <div className='cell'><span>{item.mappedPropertyName ? references.dataSources.mappedPropertyName.find(e => e.id == item.mappedPropertyName).name : '--'}</span></div>
                                <div className='cell'><span>{item.mappedDomainPropertyName ? references.dataSources.mappedDomainPropertyName.find(e => e.id == item.mappedDomainPropertyName).name : '--'}</span></div>
                                <div className='cell'>{item.dynamicsBridgePath}</div>
                                <div className='cell small'><Edit className='icon clickable pull-right' onClick={() => { onEditClick(item) }} /></div>
                                <div className='cell small'><Trash className='icon clickable pull-right' onClick={() => { onDeleteClick(item) }} /></div>
                            </div>
                        )
                    }} />

            </DataObjectContainer>
        </Card>
    )
}


export const ManualDataPopupContent = ({ popup, dataId, onSaved }) => {

    const capabilities = useContext(CapabilitiesContext);
    const loader = capabilities.getCapability(CapabilityName.Loader);
    const context = useRef(null);
    const [valid, setValid] = useState(false);


    const onSaveClick = async () => {
        popup.current.close();
        var response = await context.current.submitAsync();
        if (onSaved) {
            onSaved(response);
        }
    }

    return (
        <DataObjectContainer ref={context} dataObjectName={'featureDataObject'} dataObjectId={dataId} onValidated={valid => { setValid(valid) }} >

            <div className='topSection'>
                <div className='left'></div>
                <div className='center'><h4>New Feature</h4></div>
                <div className='right'><XSquare className='icon btn-close clickable' onClick={() => { popup.current.close() }} /></div>
            </div>

            <div className='contentSection'>

                <label htmlFor='propertyName'>Id</label>
                <BoundTextbox propertyName={'id'} />

                <label htmlFor='name'>Name</label>
                <BoundTextbox propertyName={'name'} />

                <label htmlFor='mappedPropertyName'>Dynamics Property</label>
                <BoundDropdownList propertyName={'mappedPropertyName'} caption='None' defaultValue={''} />

                <label htmlFor='mappedDomainPropertyName'>Domain Property</label>
                <BoundDropdownList propertyName={'mappedDomainPropertyName'} caption='None' defaultValue={''} />

                <label htmlFor='dynamicsBridgePath'>Dynamics Bridge Path</label>
                <BoundTextbox propertyName={'dynamicsBridgePath'} />
               
            </div>

            <div className='footerSection'>
                <button className='btn btn-primary btn-modal' onClick={() => { popup.current.close(); }}>Cancel</button>
                <button className='btn btn-secondary btn-modal' disabled={!valid} onClick={onSaveClick}>Save</button>
            </div>
        </DataObjectContainer>
    )
}

