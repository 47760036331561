import React, { useState, useRef, useContext } from 'react';
import { Popup, Confirmation } from '../core/Popup';
import { PageableList, BoundTextbox } from '../dataObjects/BoundControls';
import { Toggle } from '../core/Toggle';
import { Alert } from '../core/Alert';
import { DataObjectContainer } from '../dataObjects/DataObjectContextManager';
import { Card } from '../core/Card';
import { Textbox } from '../core/Textbox';
import { CapabilitiesContext } from '../infrastructure/Contexts';
import { CapabilityName } from '../infrastructure/Constants';
import { XSquare, Trash, Edit } from 'react-feather';

export const ControlFeaturesView = (props) => {
    var timeout = null;
    const newPopup = useRef(null);
    const confirmation = useRef(null);
    const context = useRef(null);
    const list = useRef(null);
    const [filter, setFilter] = React.useState({ name: '' });
    const [dataId, setDataId] = useState(null);

    const onFilterTextChange = (value, valid) => {
        window.clearTimeout(timeout);

        if (valid || value.length == 0) {
            timeout = window.setTimeout(() => {
                var f = filter;
                f['name'] = value ? value : null;
                setFilter(f);
                list.current.refresh();
            }, 500);
        }
    }

    const onNewClick = item => {
        setDataId(null);
        newPopup.current.show();
    }

    const onEditClick = async (item) => {
        setDataId(item.id);
        newPopup.current.show();
    }

    const onToggled = async (item, enabled) => {
        try {
            item.enabled = enabled;
            await context.current.submitItemAsync(item.id, item);
        }
        catch {
            confirmation.current.show('Error.', enabled ? 'The Feature was not enabled.' : 'The Feature was not disabled.');            
        }
        list.current.refresh();
    }

    const onDeleteClick = item => {
        confirmation.current.show('Are you sure to delete this Feature Toggle? Delete only Feature Toggles that are not used in the application', 'Delete', async () => {
            try {
                await context.current.deleteAsync(item.id);
            }
            catch (ex) {
                confirmation.current.show('This Feature Toggle can not be deleted.', 'Delete');
            }
            list.current.refresh();
        });
    }

    

    return (
        <Card title='Application Features'>

            <button className='btn btn-primary' onClick={() => { onNewClick()} }>New</button>

            <Popup ref={newPopup}>
                <NewEntryPopupContent popup={newPopup} dataId={dataId} onSaved={() => { list.current.refresh(); }} />
            </Popup>

            <Confirmation ref={confirmation} />

            <Alert type='success'>Features Settings are cached. Any change can take up to 10 minutes to be applied!</Alert>

            <Textbox onChange={(value, valid) => { onFilterTextChange(value, valid) }} value='' placeholder='Enter Feature Name to filter...' />

            <DataObjectContainer ref={context} dataObjectName={'featuretoggledataobject'} >
                <PageableList pageSize={100} ref={list} filter={filter}
                    onRenderHeaderRow={() => {
                        return (
                            <div className='row caption' >
                                <div className='cell'>Id</div>
                                <div className='cell'>Name</div>
                                <div className='cell'>Unique Id</div>
                                <div className='cell'>Description</div>
                                <div className='cell'><div className='pull-right'>Enabled</div></div>                                
                                <div className='cell small'>&nbsp;</div>                                
                            </div>
                        )
                    }}
                    onRenderRow={(key, item, references) => {
                        return (
                            <div className={'row clickable'} key={`row${key}`}>
                                <div className='cell' onClick={() => { onEditClick(item) }}>{item.id}</div>
                                <div className='cell' onClick={() => { onEditClick(item) }}>{item.name}</div>
                                <div className='cell' onClick={() => { onEditClick(item) }}>{item.uniqueId}</div>
                                <div className='cell' onClick={() => { onEditClick(item) }}>{item.description}</div>
                                <div className='cell'><div className='pull-right'><Toggle on={item.enabled} onChange={value => { onToggled(item, value) }} /></div></div>
                                <div className='cell small'>{!item.enabled && <Trash className='icon clickable pull-right' onClick={() => { onDeleteClick(item) }} />}</div>
                            </div>
                        )
                    }} />

            </DataObjectContainer>
        </Card>
    )
}




export const NewEntryPopupContent = ({ popup, dataId, onSaved }) => {

    const capabilities = useContext(CapabilitiesContext);
    const loader = capabilities.getCapability(CapabilityName.Loader);
    const context = useRef(null);
    const [valid, setValid] = useState(false);


    const onSaveClick = async () => {
        popup.current.close();
        var response = await context.current.submitAsync();
        if (onSaved) {
            onSaved(response);
        }
    }

    return (
        <DataObjectContainer ref={context} dataObjectName={'featuretoggledataobject'} dataObjectId={dataId} onValidated={valid => { setValid(valid) }} >

            <div className='topSection'>
                <div className='left'></div>
                <div className='center'><h4>New Feature</h4></div>
                <div className='right'><XSquare className='icon btn-close clickable' onClick={() => { popup.current.close() }} /></div>
            </div>

            <div className='contentSection'>

                <label htmlFor='name'>Name</label>
                <BoundTextbox propertyName={'name'} />

                <label htmlFor='mappedPropertyName'>Description</label>
                <BoundTextbox propertyName={'description'} />

            </div>

            <div className='footerSection'>
                <button className='btn btn-primary btn-modal' onClick={() => { popup.current.close(); }}>Cancel</button>
                <button className='btn btn-secondary btn-modal' disabled={!valid} onClick={onSaveClick}>Save</button>
            </div>
        </DataObjectContainer>
    )
}