import React from 'react';
import { useNavigate } from 'react-router-dom';

export const Home = (props) => {
    const navigate = useNavigate();

    return (
        <div>

            {props.features.artwork_processing && <button className='btn btn-square btn-tertiary-outline' onClick={() => { navigate('/skus') }}>Skus</button>}
            {props.features.artwork_processing && <button className='btn btn-square btn-tertiary-outline' onClick={() => { navigate('/data/definitions') }}>Data Structure Definition</button>}
            {props.features.artwork_processing && <button className='btn btn-square btn-tertiary-outline' onClick={() => { navigate('/data/features') }}>Features</button>}
            {props.features.artwork_processing && <button className='btn btn-square btn-tertiary-outline' onClick={() => { navigate('/data/colors') }}>Colors</button>}
            {props.features.jmf_processing && <button className='btn btn-square btn-tertiary-outline' onClick={() => { navigate('/data/jmf') }}>JMF Message Queue</button>}

            <button className='btn btn-square btn-tertiary-outline' onClick={() => { navigate('/audit') }}>Audit</button>
            <button className='btn btn-square btn-tertiary-outline' onClick={() => { navigate('/config') }}>Config</button>
        </div>
    );
}