import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Layout } from './components/Layout';
import { Logger } from "./infrastructure/Logger";
import { Configuration } from "./infrastructure/Configuration";
import { PushCommandClient } from "./infrastructure/NotificationSubscribers";
import { CapabilityName } from "./infrastructure/Constants";
import { CapabilityRegistry } from "./infrastructure/Capabilities";
import { BackSplashComponent } from "./capabilities/BackSplashComponent";
import { LoaderComponent } from "./capabilities/LoaderComponent";
import { ModalComponent } from "./capabilities/ModalComponent";
import { NotificationComponent } from "./capabilities/NotificationComponent";
import { CapabilitiesContext, SettingsContext, UserContext } from "./infrastructure/Contexts";
import { Home } from "./pages/Home";
import { DataStructureDefinitionView } from './pages/DataStructureDefinitionView';
import { SkusView } from './pages/SkusView';
import { AuditView, AuditDetailsView } from './pages/AuditView';
import { FeatureView } from './pages/FeatureView';
import { ColorMappingView } from './pages/ColorMappingView';
import { JmfMessageView, JmfMessageDetailView } from './pages/JmfMessageView';
import { ControlFeaturesView } from './pages/Config';

import "./order.css";
import "./global.css";
import "./custom.css";


export default function App() {
    const displayName = App.name;
    const capabilities = new CapabilityRegistry();

    const [config, setConfig] = useState(null);

    useEffect(() => {
        console.log("Application initializing");
        async function init() {

            let configuration = new Configuration();

            try {

                var config = await configuration.getConfigAsync();

                if (config) {

                    var notificationClient = new PushCommandClient(config.settings["Notifications:PushWebSocketUri"], config.settings["Security:ClientId"], config.user.sid);
                    var notificationRegistry = notificationClient.getSubscriberRegistry();

                    notificationRegistry.registerHandler(onCommandReceived);

                    var config = {
                        user: config.user,
                        settings: config.settings,
                        capabilities: capabilities,
                        notificationSubscribers: notificationRegistry,
                        features: config.features
                    }

                    console.log("config", config);

                    setConfig(config);
                    console.log("Finished");
                }
            }
            catch (ex) {
                console.log("Application initialization has failed. Redirecting to login page.", ex);
                window.location.replace("account/login");
            }
        }
        init();
    }, []);

    const onCommandReceived = (command) => {
    }

    return (
        <div className="order">

            <BackSplashComponent capabilityRegistry={capabilities} id={CapabilityName.BackSplash} />
            <LoaderComponent capabilityRegistry={capabilities} id={CapabilityName.Loader} />
            <ModalComponent capabilityRegistry={capabilities} id={CapabilityName.Modal} />
            <NotificationComponent capabilityRegistry={capabilities} id={CapabilityName.Notification} />

            {
                config &&
                <SettingsContext.Provider value={config.settings}>
                    <UserContext.Provider value={config.user}>
                        <CapabilitiesContext.Provider value={config.capabilities}>
                                <Layout>
                                    <Routes>
                                        <Route exact path="/" element={<Home features={config.features} />} />                                        
                                        <Route exact path='/skus' element={<SkusView />} />
                                        <Route exact path='/data/features' element={<FeatureView />} />                                        
                                        <Route exact path='/data/definitions' element={<DataStructureDefinitionView />} />                                        
                                        <Route exact path='/data/colors' element={<ColorMappingView />} />                                        
                                        <Route exact path='/audit' element={<AuditView />} />                                        
                                        <Route exact path='/audit/order/:order' element={<AuditView />} />                                        
                                        <Route exact path='/audit/:id' element={<AuditDetailsView />} />        
                                        <Route exact path='/data/jmf' element={<JmfMessageView />} />        
                                        <Route exact path='/data/jmf/:id' element={<JmfMessageDetailView />} />                                                
                                        <Route exact path='/config' element={<ControlFeaturesView />} />                                                                                     
                                    </Routes>
                            </Layout>
                        </CapabilitiesContext.Provider>
                    </UserContext.Provider>
                </SettingsContext.Provider>
            }
        </div>
    );
}
