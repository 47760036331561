import React, { useState, useRef, useContext } from 'react';
import { DataObjectContainer } from '../dataObjects/DataObjectContextManager';
import { PageableList, BoundTextbox, BoundDropdownList } from '../dataObjects/BoundControls';
import { Popup, Confirmation } from '../core/Popup';
import { XSquare, Trash, Edit } from 'react-feather';
import { Card } from '../core/Card';
import { CapabilitiesContext } from '../infrastructure/Contexts';
import { CapabilityName } from '../infrastructure/Constants';

export const ColorMappingView = () => {

    const entryPopup = useRef(null);
    const confirmation = useRef(null);
    const context = useRef(null);
    const list = useRef(null);
    const [dataId, setDataId] = useState(null);

    const onNewClick = (item) => {
        entryPopup.current.show();
    }

    const onEditClick = (item) => {
        setDataId(item.id);
        entryPopup.current.show();
    }

    const onDeleteClick = (item) => {
        confirmation.current.show('Are you sure to delete this Color Mapping?', 'Delete', async () => {
            try {
                await context.current.deleteAsync(item.id);
            }
            catch (ex) {
                confirmation.current.show('This Color Mapping can not be deleted.', 'Delete');
            }
            list.current.refresh();
        });
    }


    return (
        <Card title='Colors'>

            <button className='btn btn-primary' onClick={() => { onNewClick() }}>New</button>

            <Popup ref={entryPopup}>
                <EntryPopupContent popup={entryPopup} dataId={dataId} onSaved={() => { list.current.refresh(); }} />
            </Popup>

            <Confirmation ref={confirmation} />

            <DataObjectContainer ref={context} dataObjectName={'colorMappingListDataObject'} >

                <PageableList pageSize={100} ref={list}
                    onRenderHeaderRow={() => {
                        return (
                            <div className='row caption' >
                                <div className='cell'>Id</div>
                                <div className='cell'>Dynamics Environment</div>
                                <div className='cell'>Dynamics Color</div>
                                <div className='cell'>Esko Color</div>
                                <div className='cell small'>&nbsp;</div>
                                <div className='cell small'>&nbsp;</div>
                            </div>
                        )
                    }}
                    onRenderRow={(key, item, references) => {
                        return (
                            <div className='row' key={`row${key}`}>
                                <div className='cell'>{item.id}</div>
                                <div className='cell'>{item.dynamicsEnvironment}</div>
                                <div className='cell'>{item.dynamicsColor}</div>
                                <div className='cell'>{item.eskoColor}</div>
                                <div className='cell small'><Edit className='icon clickable pull-right' onClick={() => { onEditClick(item) }} /></div>
                                <div className='cell small'><Trash className='icon clickable pull-right' onClick={() => { onDeleteClick(item) }} /></div>
                            </div>
                        )
                    }} />

            </DataObjectContainer>

        </Card>
    )
}


export const EntryPopupContent = ({ popup, dataId, onSaved }) => {

    const capabilities = useContext(CapabilitiesContext);
    const loader = capabilities.getCapability(CapabilityName.Loader);
    const context = useRef(null);
    const [valid, setValid] = useState(false);
    const [dynamicsEnvironmentId, setDynamicsEnvironmentId] = useState(null);


    const onSaveClick = async () => {
        popup.current.close();
        var response = await context.current.submitAsync();
        if (onSaved) {
            onSaved(response);
        }
    }

    const onDynamicsEnvironmentChanged = (item) => {
        setDynamicsEnvironmentId(item);
    }

    const getFilter = () => {
        if (dynamicsEnvironmentId) {
            return {
                dynamicsenvironmentid: dynamicsEnvironmentId
            }
        }       
    }

    return (
        <DataObjectContainer ref={context} dataObjectName={'colorMappingDataObject'} dataObjectId={dataId} onValidated={valid => { setValid(valid) }} >

            <div className='topSection'>
                <div className='left'></div>
                <div className='center'><h4>New Feature</h4></div>
                <div className='right'><XSquare className='icon btn-close clickable' onClick={() => { popup.current.close() }} /></div>
            </div>

            <div className='contentSection'>

                <label htmlFor='dynamicsEnvironment'>Dynamics Environment</label>
                <BoundDropdownList propertyName={'dynamicsEnvironment'} onChange={item => { onDynamicsEnvironmentChanged(item)}} />

                <label htmlFor='dynamicsColor'>Dynamics Color</label>
                <BoundDropdownList propertyName={'dynamicsColor'} filter={getFilter()} />

                <label htmlFor='eskoColor'>Esko Color</label>
                <BoundTextbox propertyName={'eskoColor'} />                
            </div>

            <div className='footerSection'>
                <button className='btn btn-primary btn-modal' onClick={() => { popup.current.close(); }}>Cancel</button>
                <button className='btn btn-secondary btn-modal' disabled={!valid} onClick={onSaveClick}>Save</button>
            </div>
        </DataObjectContainer>
    )
}
