export class Regex {

    static Matches(exp, value) {
        var result = true;
        var regex = exp ? exp : null;
        if (regex) {
            var r = new RegExp(regex, 'g');
            var match = r.exec(value);
            if (match == null || match[0] != value) {
                result = false;
            }
        }
        return result;
    }

}
