import React, { useEffect, useState, forwardRef, useContext, useImperativeHandle } from 'react';
import { DataObjectContext, DataSource } from './DataObjectContextManager';
import { Regex } from './Regex';
import { SkipBack, SkipForward } from 'react-feather';
import { Toggle } from '../core/Toggle';


import './BoundControls.css';


export const BoundToggle = ({ propertyName, className }) => {

    const dataObjectContextManager = useContext(DataObjectContext);
    const property = dataObjectContextManager.getProperty(propertyName);
    const val = property.getValue();
    const [value, setValue] = React.useState(val !== null ? val == true : false);

    const getDefaultValue = () => {
        var val = property.getValue();
        return val !== true ? true : false;
    }

    useEffect(() => {
        property.setValid(isValid(val));
    }, []);

    const handleChange = (value, ctrl) => {

        setValue(value);
        property.setValue(value);

        var vld = isValid(value);
        property.setValid(vld);
    };

    const isValid = (value) => {
        return true;
    }

    return (
        <div>
            <Toggle id={propertyName} on={getDefaultValue()} onChange={handleChange} />
        </div>
        
    )
}


export const BoundCheckbox = ({ propertyName, className }) => {

    const dataObjectContextManager = useContext(DataObjectContext);
    const property = dataObjectContextManager.getProperty(propertyName);
    const val = property.getValue();    
    const [value, setValue] = React.useState(val !== null ? val == true : false);

    const getDefaultValue = () => {
        var val = property.getValue();
        return val !== true ? true : false;
    }

    useEffect(() => {
        property.setValid(isValid(val));
    }, []);

    const handleChange = (event) => {
        var input = event.target.value;

        setValue(input);
        property.setValue(input);

        var vld = isValid(input);
        property.setValid(vld);
    };

    const isValid = (value) => {
        return true;
    }

    return (
        <input id={propertyName} type='checkbox' className={`control checkbox ${className} ${isValid(value) ? 'valid' : 'error'}`} value={getDefaultValue()} onChange={handleChange} />
    )
}


export const PageableList = forwardRef(({ className, pageSize, pageIndex, onRenderRow, onRenderHeaderRow, filter, defaultText, pagingDisabled }, ref) => {

    const dataObjectContextManager = useContext(DataObjectContext);
    const [references, setReferences] = React.useState([]);
    const [items, setItems] = React.useState([]);
    const [index, setIndex] = React.useState(pageIndex ? pageIndex : 0);
    const [previousEnabled, setPreviousEnabled] = React.useState(false);
    const [nextEnabled, setNextEnabled] = React.useState(false);
    const [ready, setReady] = React.useState(null);
    
    useEffect(() => {        
        loadAsync();
    }, [pageSize, pageIndex]);

    useImperativeHandle(ref, () => ({

        refresh() {
            loadAsync(index);
        }

    }));


    const loadAsync = async (pageIndex) => {
        if (ready == null) {
            setReady(false);
        }
        var response = await dataObjectContextManager.list(pageIndex ? pageIndex : 0, pageSize ? pageSize : 20, filter);

        setPreviousEnabled(response.value.pageIndex > 0);
        setNextEnabled(response.value.items && response.value.items.length == pageSize);

        setReferences(response.references);
        setItems(response.value.items);
        setIndex(response.value.pageIndex);        
        setReady(true);
    }

    const onPreviousClick = async () => {
        var idx = index > 0 ? index - 1 : 0;        
        await loadAsync(idx);
    }

    const onNextClick = async () => {
        var idx = index + 1;
        await loadAsync(idx);
    }

    const showTable = () => {
        return items && items.length === 0 && index === 0
            ? false
            : true;
    }

    return (
        <div className={className ? `list ${className}` : `list`}>
            {ready &&
                <div>
                    <div className='table'>
                        {onRenderHeaderRow &&
                            onRenderHeaderRow()
                        }

                        {showTable() && onRenderRow && items.map((item, idx) => {
                            return (
                                onRenderRow(item.key, item.dataObject, references, idx)
                            )
                        })}
                                               
                    </div>

                    {showTable()
                        ?
                        <div>
                            {!pagingDisabled && <button disabled={!previousEnabled} className='btn btn-secondary-outline' onClick={onPreviousClick}><SkipBack className='icon' /><span className='text'></span></button>}
                            {!pagingDisabled && <button disabled={!nextEnabled} className='btn btn-secondary-outline pull-right' onClick={onNextClick}><SkipForward className='icon' /><span className='text'></span></button>}
                        </div>
                        :
                        <div><p>{defaultText}</p></div>
                    }
                    
                </div>
            }
        </div>
        )
});


export const RenderConditionally = ({ propertyName, value, children }) => {
    const dataObjectContextManager = useContext(DataObjectContext);
    const property = dataObjectContextManager.getProperty(propertyName);
    const val = property.getValue();

    return (
        <>
            {value == val &&
                <>
                    {children}
                </>
            }
        </>
    )
}


export const BoundLabel = ({ propertyName, display, onRender }) => {
    const dataObjectContextManager = useContext(DataObjectContext);
    const property = dataObjectContextManager.getProperty(propertyName);
    const dataSourceName = property.property.attributes.dataSource ? property.property.attributes.dataSource.name : null;
    const [value, setValue] = React.useState(null);

    useEffect(() => {
        init();
    }, []);


    const init = async () => {
        const val = property.getValue();

        if (dataSourceName) {
            var dataSource = new DataSource(dataSourceName);
            var items = await dataSource.getItems();
            var item = items.find(e => e.id == val);
            if (item) {
                if (display) {
                    setValue(item[display])
                }
                else {
                    setValue(item.name)
                }
            }
        }
        else {
            setValue(val);
        }
    }

    return (
        <span id={propertyName}>{onRender ? onRender(value) : value}</span>
    )
}

export const BoundTextbox = ({ propertyName, className }) => {

    const dataObjectContextManager = useContext(DataObjectContext);    
    const property = dataObjectContextManager.getProperty(propertyName);
    const val = property.getValue();
     
    const maxLength = property.hasStringLength() && property.getStringLength().maximumLength > 0 ? property.getStringLength().maximumLength : null;
    const [value, setValue] = React.useState(val !== null ? val : '');

    const getDefaultValue = () => {
        var val = property.getValue();
        return val !== null ? val : '';
    }

    useEffect(() => {
        property.setValid(isValid(val));
    }, []);

    const handleChange = (event) => {
        var input = event.target.value;
        if (property.hasRegex() && !Regex.Matches(property.getRegex().expression, input)) {
            return;
        }

        setValue(input);
        property.setValue(input);

        var vld = isValid(input);
        property.setValid(vld);
    };

    const isValid = (value) => {

        if (property.isRequired()) {
            if (!value) {
                return false;
            }
        }
        else {
            if (value && value.length > 0) {
                if (property.hasStringLength()) {
                    if (property.getStringLength().minimumLength && value.length < property.getStringLength().minimumLength) {
                        return false;
                    }
                    if (property.getStringLength().maximumLength && value.length > property.getStringLength().maximumLength) {
                        return false;
                    }
                }

                if (property.hasRegex() && !Regex.Matches(property.getRegex().expression, value)) {
                    return false;
                }
            }

        }
        return true;
    }

    return (
        <div>            
            <input id={propertyName} type='text' className={`control textbox ${isValid(value) ? 'valid' : 'error'} ${className}`} maxLength={maxLength} value={getDefaultValue()} onChange={handleChange} />
        </div>
    )
}


export const BoundTextarea = ({ propertyName, className, rows, cols }) => {

    const dataObjectContextManager = useContext(DataObjectContext);
    const property = dataObjectContextManager.getProperty(propertyName);
    const val = property.getValue();

    const maxLength = property.hasStringLength() && property.getStringLength().maximumLength > 0 ? property.getStringLength().maximumLength : null;
    const [value, setValue] = React.useState(val !== null ? val : '');

    const getDefaultValue = () => {
        var val = property.getValue();
        return val !== null ? val : '';
    }

    useEffect(() => {
        property.setValid(isValid(val));
    }, []);

    const handleChange = (event) => {
        var input = event.target.value;

        if (property.hasRegex() && !Regex.Matches(property.getRegex().expression, input)) {
            return;
        }

        setValue(input);
        property.setValue(input);

        var vld = isValid(input);
        property.setValid(vld);
    };

    const isValid = (value) => {

        if (property.isRequired()) {
            if (!value) {
                return false;
            }
        }
        else {
            if (value && value.length > 0) {
                if (property.hasStringLength()) {
                    if (property.getStringLength().minimumLength && value.length < property.getStringLength().minimumLength) {
                        return false;
                    }
                    if (property.getStringLength().maximumLength && value.length > property.getStringLength().maximumLength) {
                        return false;
                    }
                }

                if (property.hasRegex() && !Regex.Matches(property.getRegex().expression, value)) {
                    return false;
                }
            }

        }
        return true;
    }

    return (
        <textarea id={propertyName} className={`control textbox textarea ${isValid(value) ? 'valid' : 'error'} ${className}`} rows={rows ? rows : null} cols={cols ? cols : null} maxLength={maxLength} value={getDefaultValue()} onChange={handleChange} />
    )

}




export const BoundDropdownList = ({ propertyName, dataSourceName, useFirstValue, caption, defaultValue, disabled, filter, className, onChange }) => {

    const defaultSelectedValue = defaultValue !== undefined ? defaultValue : -1;
    const dataObjectContextManager = useContext(DataObjectContext);
    const property = dataObjectContextManager.getProperty(propertyName);

    const val = property.getValue();

    const [showLoader, setShowLoader] = useState(false);
    const [items, setItems] = useState(null);
    const [value, setValue] = React.useState(val !== null ? val : defaultSelectedValue);


    const getDefaultValue = () => {
        var val = property.getValue();
        return val !== null ? val : defaultSelectedValue;
    }

    useEffect(() => {

        const init = async () => {

            setShowLoader(true);
            var dataSource = property.getDataSource();
            if (!dataSource) {
                throw new Error('DataSource is not supported!');
            }

            var items = dataSourceName
                ? await dataObjectContextManager.getDataSourceItems(dataSourceName, filter)
                : await dataSource.getItems(filter);


            setItems(items);

            if (items) {
                var val = property.getValue();
                if (useFirstValue && items && items.length > 0) {                    
                    broadcastChange(items[0].id);
                }
                else {
                    var exists = items.find(e => e.id == val);
                    if (!exists) {
                        broadcastChange(defaultSelectedValue);
                    }
                }
            }
            setShowLoader(false);
        }
          
        init(); 
        
    }, [filter]);

    const handleChange = (event) => {
        var val = event.target.value;
        broadcastChange(val);
    };

    const broadcastChange = (val) => {
        setValue(val);
        property.setValue(val);
        property.setValid(isValid(val));

        if (onChange) {
            onChange(val)
        }
    }


    const isValid = (val) => {        

        if (property.isRequired()) {
            if (val == defaultSelectedValue) {
                return false;
            }

            if (!items || items.length == 0) {
                return false;
            }
        }

        return true;
    }



    return (
        <div>
            {showLoader &&
                <div className='controlLoader'>
                    <img src='/images/Rolling-1s-40px.gif' />
                </div>
            }
            <select id={propertyName} className={`control dropdown ${isValid(value) ? 'valid' : 'error'} ${className}`} value={getDefaultValue()} disabled={disabled === true || showLoader || !items || items.length==0} onChange={handleChange}>
                {!useFirstValue &&
                    <option value={defaultSelectedValue}>{caption ? caption : 'Please select...'}</option>
                }
                {items &&
                    <>
                        {items.map((item, idx) => {
                            return (
                                <option key={`option${idx}`} value={item.id}>{item.name}</option>
                            )
                        })}
                    </>
                }
            </select>

        </div>
    )
}

