import React, { useState, useContext, useEffect } from 'react';
import { XSquare } from 'react-feather';
import { ApiDataAccess } from '../infrastructure/ApiDataAccess';
import { CapabilityName } from '../infrastructure/Constants';
import { CapabilitiesContext } from '../infrastructure/Contexts';
import { Textbox } from '../core/Textbox';
import { DropdownList } from '../core/DropdownList';

export const SkuManualEntryPopup = ({ popup, onCloseClick }) => {

    const capabilities = useContext(CapabilitiesContext);
    const loader = capabilities.getCapability(CapabilityName.Loader);

    const [valid, setValid] = useState(false);
    const [dataDefinition, setDataDefinition] = useState(null);

    const [data, setData] = useState({});
    const [validProps, setValidProps] = useState({});

    const [error, setError] = useState(null);

    useEffect(() => {
        const init = async () => {
            var items = [];
            var vp = {};
            loader.show();
            var dataAccess = new ApiDataAccess('/api/dataobjects');
            dataAccess.get('/dataStructureDefinitionDataObject?pageSize=1000&pageIndex=0')
                .then(data => {
                    data.value.value.items.forEach(item => {
                        items.push(item.dataObject);
                        vp[item.dataObject.propertyName] = !item.dataObject.required;
                    });
                    setValidProps(vp);
                    setDataDefinition(items);
                    loader.hide();
                })
                .catch(() => {
                    loader.hide();
                })
        }
        init();
    }, []);

    const getRegex = (definition) => {
        switch (definition.dataType) {
            case 1:
                return null;
            case 2:
                return '[0-9]*';
            case 3:
                return '[0-9]*(\.[0-9]\{0,4\})?';
            default:
                return null;
        }
    }

    const onChange = (prop, value, valid) => {

        var vp = validProps;
        vp[prop] = valid;
        setValidProps(vp);

        if (valid) {
            setData(data => ({ ...data, [prop]: value != 'null' ? value : null }));
        }

        var val = true;
        Object.keys(vp).forEach(key => {
            console.log(key, vp[key]);

            if (!vp[key]) {
                val = false;
            }
        });
        setValid(val);
    }

    const onSaveClick = async () => {
        loader.show();

        setError(null);
        var dataAccess = new ApiDataAccess('/token');
        dataAccess.get('/epac')
            .then(token => {
                const urlEncodedDataPairs = [];
                Object.keys(data).forEach(prop => {
                    urlEncodedDataPairs.push(`<${prop}>${encodeURIComponent(data[prop])}</${prop}>`);
                });
                const urlEncodedData = urlEncodedDataPairs.join("");

                var xml = '<?xml version="1.0" encoding="utf-8"?>';
                xml += '<Root xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema">';
                xml += urlEncodedData;
                xml += '</Root>';

                var xmlhttp = new XMLHttpRequest();
                xmlhttp.open("POST", "api/esko/metaData");
                var xmlDoc;
                xmlhttp.onreadystatechange = function () {
                    if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
                        xmlDoc = xmlhttp.responseXML;
                        console.log(xmlDoc);
                        popup.current.close();
                        if (onCloseClick) {
                            onCloseClick();
                        }
                    }
                    else {
                        var json = null;
                        try {
                            json = JSON.parse(xmlhttp.response);
                        }
                        catch {
                            json = null;
                        }
                        setError(json && json.detail ? json.detail : 'Unknown error');
                    }
                    loader.hide();
                };

                xmlhttp.setRequestHeader('Content-Type', 'application/xml');
                xmlhttp.setRequestHeader('Authorization', token);

                xmlhttp.send(xml);
            });

    }


    return (
        <div>
            <div className='topSection'>
                <div className='left'></div>
                <div className='center'><h4>Manual Data</h4></div>
                <div className='right'><XSquare className='icon btn-close clickable' onClick={() => { popup.current.close() }} /></div>
            </div>


            {dataDefinition &&
                <div className='contentSection'>
                    <table>
                        <thead>
                            <tr>
                                <th>Property</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataDefinition.map((def, idx) => {
                                return (
                                    <tr key={`txt_${idx}`}>
                                        <td>{def.propertyName} </td>
                                        <td>
                                            {def.featureMapped !== null && def.featureMapped >= 0
                                                ? <MappedPropertyDropdown featureMapped={def.featureMapped} required={def.required} onChange={(value, valid) => { onChange(def.propertyName, value, valid) }} />
                                                : <Textbox required={def.required} regex={getRegex(def)} onChange={(value, valid) => { onChange(def.propertyName, value, valid) }} value={''} />
                                            }
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>

                    {error &&
                        <div className='alert error'>Could not create Meta Data: {error}</div>
                    }

                </div>
            }

            <div className='footerSection'>
                <button className='btn btn-primary btn-modal' onClick={() => { popup.current.close(); }}>Cancel</button>
                <button className='btn btn-secondary btn-modal' disabled={!valid} onClick={onSaveClick}>Save</button>
            </div>
        </div>
    )
}

export const MappedPropertyDropdown = ({ featureMapped, required, onChange }) => {

    const [items, setItems] = useState(null);

    useEffect(() => {
        const init = async () => {
            var dataAccess = new ApiDataAccess('/api/datasources');
            dataAccess.get(`/DynamicsCalculationUnits?featureMapped=${featureMapped}`)
                .then(data => {
                    setItems(data);
                })
                .catch(() => {
                })
        }
        init();
    }, []);

    const onSelected = item => {
        if (onChange) {
            if (item) {
                onChange(item.id, true);
            }
            else {
                onChange(null, true);
            }
        }
    }

    return (
        <div>
            {items &&
                <DropdownList defaultText={'None'} items={items} required={false} onChange={item => onSelected(item)} />
            }
        </div>
    )
}