import React, { useState, useRef } from 'react';
import { useParams } from 'react-router';
import { Trash } from 'react-feather';
import { Card } from '../core/Card';
import { DataObjectContainer } from '../dataObjects/DataObjectContextManager';
import { BoundLabel, BoundDropdownList, PageableList } from '../dataObjects/BoundControls';
import { useNavigate } from 'react-router-dom';
import { Confirmation } from '../core/Popup';
import { Status, StatusDisplay } from '../core/StatusDisplay';


export const JmfMessageView = () => {

    const context = useRef(null);
    const list = useRef(null);
    const confirmation = useRef(null);

    const deleteItem = async (item) => {
        confirmation.current.show('Are you sure to delete this JMF message?', 'Delete', async () => {
            try {
                await context.current.deleteAsync(item.id);
            }
            catch (ex) {
                confirmation.current.show('This JMF message can not be deleted.', 'Delete');
            }
            list.current.refresh();
        });              
    }

    const getStatus = (item) => {

        if (item.ageInMinutes > 60)
            return Status.Failure;

        if (item.ageInMinutes > 15)
            return Status.Warn;

        return Status.Success;
    }

    return (
        <Card title='JMF Messages' subtitle=''>

            <Confirmation ref={confirmation} />

            <DataObjectContainer ref={context} dataObjectName={'jmfMessageDataObject'} >
                <PageableList pageSize={100} ref={list} filter={null}
                    onRenderHeaderRow={() => {
                        return (
                            <div className='row caption' >
                                <div className='cell'>Id</div>
                                <div className='cell'>Order</div>
                                <div className='cell'>Target Plant</div>
                                <div className='cell'>Age (in Minutes)</div>
                                <div className='cell'>Date</div>
                                <div className='cell'></div>
                            </div>
                        )
                    }}
                    onRenderRow={(key, item, references) => {
                        return (
                            <div className={'row clickable'} key={`row${key}`}>
                                <div className='cell' onClick={() => { document.location = `/data/jmf/${item.id}` }}>{item.id}</div>
                                <div className='cell' onClick={() => { document.location = `/data/jmf/${item.id}` }}>{item.orderNumber > 0 ? item.orderNumber : 'N/A'}</div>                                
                                <div className='cell' onClick={() => { document.location = `/data/jmf/${item.id}` }}><span>{item.targetPlantId !== null && item.targetPlantId >= 0 ? references.dataSources.targetPlantId.find(e => e.id == item.targetPlantId).name : '--'}</span></div>
                                <div className='cell' onClick={() => { document.location = `/data/jmf/${item.id}` }}><div className='flex'><StatusDisplay status={getStatus(item)} hideText={true} /> {item.ageInMinutes}</div></div>
                                <div className='cell' onClick={() => { document.location = `/data/jmf/${item.id}` }}>{item.createDate}</div>
                                <div className='cell'><button className='btn btn-primary btn-sm pull-right' onClick={() => {deleteItem(item)}}><Trash /></button></div>
                            </div>
                        )
                    }} />

            </DataObjectContainer>

        </Card>
    )
}


export const JmfMessageDetailView = () => {

    const navigate = useNavigate();
    const confirmation = useRef(null);
    const { id } = useParams();
    const context = useRef(null);
    const [valid, setValid] = useState(false);

    const deleteItem = async () => {
        confirmation.current.show('Are you sure to delete this JMF message?', 'Delete', async () => {
            try {
                await context.current.deleteAsync(id);
                navigate('/data/jmf');
            }
            catch (ex) {
                confirmation.current.show('This JMF message can not be deleted.', 'Delete');
            }
        }); 
    }

    const saveItem = async () => {
        try {
            await context.current.submitAsync();
            navigate('/data/jmf');
        }
        catch (ex) {
            confirmation.current.show('Could not save the changes.', 'Save');
        }
    }

    const getDate = value => {

        var date = new Date(value);
        return `${date.toLocaleDateString()} - ${date.toLocaleTimeString()} UTC`;
    }

    return (

        <Card title={`JMF Message ${id}`} className='jmfMessageDetail' subtitle=''>

            <Confirmation ref={confirmation} />

            <DataObjectContainer ref={context} dataObjectName={'jmfMessageDataObject'} dataObjectId={id} onValidated={valid => { setValid(valid) }} >
                <div className='contentSection'>

                    <label htmlFor='id'>Id</label>
                    <BoundLabel propertyName={'id'} />

                    <label htmlFor='order'>Order</label>
                    <BoundLabel propertyName={'orderNumber'} onRender={value => { return value ? value : 'N/A' }} />

                    <label htmlFor='targetPlantId'>Target Plant</label>
                    <BoundDropdownList propertyName={'targetPlantId'} />

                    <label htmlFor='message'>Message</label>
                    <pre><BoundLabel propertyName={'message'} /></pre>

                    <label htmlFor='ageInMinutes'>Age (in Minutes)</label>
                    <BoundLabel propertyName={'ageInMinutes'} />

                    <label htmlFor='createDate'>Create Date</label>
                    <BoundLabel propertyName={'createDate'} onRender={value => { return getDate(value) }} />

                    <label htmlFor='createDate'> Related Audit</label>
                    <RelatedAuditsListView queueId={id} />

                </div>

                <div className='footerSection'>
                    <button className='btn btn-primary-outline' onClick={() => { deleteItem() } }>Delete</button>
                    <button className='btn btn-secondary pull-right' disabled={!valid} onClick={() => { saveItem() }}>Save</button>
                </div>

            </DataObjectContainer>

        </Card>
    )
}


export const RelatedAuditsListView = ({ queueId }) => {

    const context = useRef(null);

    return (
    
        <DataObjectContainer ref={context} dataObjectName={'auditDataObject'} >

            <PageableList pageSize={100} pagingDisabled={true} filter={{ reference: `JMF-${queueId}` }}
                onRenderHeaderRow={() => {
                    return (
                        <div className='row caption' >
                            <div className='cell'>Id</div>
                            <div className='cell'>Order</div>
                            <div className='cell'>Sku</div>
                            <div className='cell'>Activity</div>
                            <div className='cell'>Status</div>
                            <div className='cell'>User</div>
                            <div className='cell'>Date</div>
                        </div>
                    )
                }}
                onRenderRow={(key, item, references) => {
                    return (
                        <div className={'row clickable'} key={`row${key}`} onClick={() => { document.location = `/audit/${item.id}` }}>
                            <div className='cell'>{item.id}</div>
                            <div className='cell'>{item.orderNumber > 0 ? item.orderNumber : 'N/A'}</div>
                            <div className='cell'>{item.skuNumber > 0 ? item.skuNumber : 'N/A'}</div>
                            <div className='cell'>{item.activity}</div>
                            <div className='cell'>{item.responseStatus}</div>
                            <div className='cell'>{item.createUser}</div>
                            <div className='cell'>{item.createDate}</div>
                        </div>
                    )
                }} />

        </DataObjectContainer>
    
    )

}