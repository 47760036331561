import React, { useEffect, useState, forwardRef, useImperativeHandle, useContext } from 'react';
import { ApiDataAccess } from '../infrastructure/ApiDataAccess';
import { CapabilitiesContext } from '../infrastructure/Contexts';
import { CapabilityName } from '../infrastructure/Constants';


export const DataObjectContext = React.createContext();

export const DataObjectContainer = forwardRef(({ dataObjectId, dataObjectName, children, onValidated, onChanged, defaultData, onInit}, ref) => {
    
    const [dataContextManager, setDataContextManager] = useState(null);
    const capabilities = useContext(CapabilitiesContext);
    const loader = capabilities.getCapability(CapabilityName.Loader);

    useEffect(() => {

        const init = async (name) => {
            var ctxt = new DataObjectContextManager(name, dataObjectId, defaultData, loader);
            await ctxt.init();
            await ctxt.load();
            setDataContextManager(ctxt);

            if (onValidated) {
                ctxt.registerValidationCallback(onValidated);
            }

            if (onChanged) {
                ctxt.registerPropertyValueChangedCallback(onChanged);
            }

            if (onInit) {
                onInit(ctxt)
            }
        }
        init(dataObjectName);
    }, []);

    useImperativeHandle(ref, () => ({

        submitAsync() {
            return new Promise((resolve, reject) => {
                dataContextManager.submitContextAsync()
                    .then(data => {
                        resolve(data);
                    })
                    .catch(ex => {
                        reject(ex);
                })
            })
        },

        loadById(id) {
            return new Promise((resolve, reject) => {
                dataContextManager.loadById(id)
                    .then(data => {
                        resolve();
                    })
                    .catch(ex => {
                        reject(ex);
                    })
            })
        },

        deleteAsync(id) {
            return new Promise((resolve, reject) => {
                dataContextManager.deleteById(id)
                    .then(data => {
                        resolve();
                    })
                    .catch(ex => {
                        reject(ex);
                    })
            })
        },

        submitItemAsync(id, item) {
            return new Promise((resolve, reject) => {
                dataContextManager.submitItemAsync(id, item)
                    .then(data => {
                        resolve();
                    })
                    .catch(ex => {
                        reject(ex);
                    })
            })
        },

        getProperty(propertyName) {           
            return dataContextManager.getProperty(propertyName);
        }

    }));


    return (
        <div>
            {dataContextManager &&
                <DataObjectContext.Provider value={dataContextManager}>
                    {children}
                </DataObjectContext.Provider>
            }
        </div>
        )
});


export class DataObjectContextManager {

    constructor(dataObjectName, id, defaultData, loader) {
        this.loader = loader;
        this.dataObjectName = dataObjectName;
        this.id = id;
        this.defaultData = defaultData;
        this.dataAccess = new ApiDataAccess('api/dataobjects');
        this.metaData = null;
        this.propertyNames = null;
        this.properties = {};
        this.validationCallbacks = [];
        this.propertyValueChangedCallbacks = [];        
    }

    async init() {
        this.loader.show();
        var obj = await this.dataAccess.get(`/metadata/${this.dataObjectName}`);
        this.metaData = obj;
        this.propertyNames = Object.keys(this.metaData);
        this.propertyNames.forEach(propertyName => {
            this.properties[propertyName] = new DataObjectProperty(this, propertyName, this.metaData[propertyName]);
        });
        
        if (this.defaultData) {
            Object.keys(this.defaultData).forEach(key => {
                this.getProperty(key).setValue(this.defaultData[key]);
            });
        }
        this.loader.hide();
        return this;

    }

    async load() {
        this.loader.show();
        await this.loadById(this.id);
        this.loader.hide();
    }

    async loadById(id) {
        this.loader.show();
        this.id = id;
        if (id) {
            var obj = await this.dataAccess.get(`/${this.dataObjectName}/${id}`);
            var dataObject = obj.value.value.dataObject;
            var propertyNames = Object.keys(dataObject);
            propertyNames.forEach(name => {
                this.getProperty(name).setValue(dataObject[name]);
            })
        }
        this.loader.hide();
    }

    async deleteById(id) {
        this.loader.show();
        this.id = id;
        if (id) {
            await this.dataAccess.delete(`/${this.dataObjectName}/${id}`);
        }
        this.loader.hide();
    }


    async list(pageIndex, pageSize, filter) {
        this.loader.show();
        var filterStr = "";
        if (filter) {
            Object.keys(filter).forEach(key => {
                filterStr += `&${key}=${encodeURIComponent(filter[key])}`;
            });
        }

        var response = await this.dataAccess.get(`/${this.dataObjectName}?pageIndex=${pageIndex}&pageSize=${pageSize}${filterStr}`);
        this.loader.hide();
        return response.value;
    }

    async getDataSourceItems(name, filter) {
        var dataSource = new DataSource(name);
        return await dataSource.getItems(filter);
    }


    isValid() {
        var valid = true;
        this.propertyNames.forEach(propertyName => {
            if (!this.properties[propertyName].getValid()) {
                console.log('Invalid:', propertyName);
                valid = false;
            }
        });
        return valid;
    }

    validateContext() {
        if (this.validationCallbacks) {
            var valid = this.isValid();
            this.notifyValidationCallbacks(valid);
        }
    }

    async submitContextAsync() {        
        var request = {};
        this.propertyNames.forEach(propertyName => {
            var value = this.properties[propertyName].getValue();
            request[propertyName] = value;
        });

        console.log("Submitting Context", request);

        return await this.submitItemAsync(this.id, request);
    }


    async submitItemAsync(id, item) {
        try {
            if (id) {
                this.loader.show();
                var key = await this.dataAccess.put(`/${this.dataObjectName}/${id}`, item);
                this.loader.hide();
                return this.id;
            }
            else {
                this.loader.show();
                var key = await this.dataAccess.post(`/${this.dataObjectName}`, item);
                this.loader.hide();
                return key;
            }
        }
        catch (ex) {
            this.loader.hide();
            throw ex;
        }
    }


    getDataObjectName() {
        return this.dataObjectName;
    }

    getProperty(propertyName) {       
        return this.properties[propertyName]
    }

    registerValidationCallback(func) {
        this.validationCallbacks.push(func);
    }

    registerPropertyValueChangedCallback(func) {
        this.propertyValueChangedCallbacks.push(func);
    }

    notifyPropertyValueChangedCallbacks(propertyName, value) {
        this.propertyValueChangedCallbacks.forEach(callback => {
            callback(propertyName, value, this);
        })
    }  

    notifyValidationCallbacks(valid) {        
        this.validationCallbacks.forEach(callback => {
            callback(valid, this);
        });        
    }  

}


export class DataObjectProperty {

    constructor(dataObjectContextManager, propertyName, property) {
        this.dataObjectContextManager = dataObjectContextManager;
        this.propertyName = propertyName;
        this.property = property;
        this.attributes = this.property.attributes;
        this.value = null;
        this.valid = true; 
    }

    getDataSource() {
        return this.attributes.dataSource
            ? new DataSource(this.attributes.dataSource.name)
            : null;
    }

    getRequired() {
        return this.attributes.required
            ? this.attributes.required
            : null;
    }

    isRequired() {
        return this.getRequired()
            ? true
            : false;
    }

    getRegex() {
        return this.attributes.regex
            ? this.attributes.regex
            : null;
    }

    hasRegex() {
        return this.getRegex()
            ? true
            : false;
    }

    getStringLength() {
        return this.attributes.stringLength
            ? this.attributes.stringLength
            : null;
    }

    hasStringLength() {
        return this.getStringLength()
            ? true
            : false;
    }

    setValue(value) {
        this.value = value;
        this.dataObjectContextManager.notifyPropertyValueChangedCallbacks(this.propertyName, this.value);
    }

    getValue() {
        return this.value;
    }

    setValid(valid) {
        this.valid = valid;
        this.dataObjectContextManager.validateContext(); 
    }

    getValid() {
        return this.valid;
    }
}


export class DataSource {

    constructor(dataSourceName) {
        this.dataSourceName = dataSourceName;
        this.dataAccess = new ApiDataAccess('api/datasources');        
    }

    async getItems(filter) {

        if (filter) {
            var data = await this.dataAccess.get(`/${this.dataSourceName}?${Tools.convertObjectToQueryString(filter)}`);
            return data;
        }
        else {
            var data = await this.dataAccess.get(`/${this.dataSourceName}`);
            return data;
        }
    }
}

export class Tools {

    static convertObjectToQueryString(obj) {
        var result = "";
        if (obj) {
            Object.keys(obj).forEach(key => {
                result += result == "" ? `${key}=${encodeURIComponent(obj[key])}` : `&${key}=${encodeURIComponent(obj[key])}`;
            });
        }

        return result;
    }

}
