import { ApiDataAccess } from './ApiDataAccess';

export class Configuration {

    getConfigAsync() {
        var actions = [
            this.getUserAsync(),
            this.getSettingsAsync(),
            this.getFeaturesAsync()
        ];

        return new Promise((resolve, reject) => {
            Promise.all(actions)
                .then(res => {
                    var config = {
                        user: res[0],
                        settings: res[1],
                        features: res[2]
                    };
                    resolve(config);
                })
                .catch(ex => reject(ex));

        });
    }

    getUserAsync() {
        return new Promise((resolve, reject) => {
            var dataAccess = new ApiDataAccess('api/application');
            dataAccess.get('/user')
                .then(user => {
                    resolve(user);
                })
                .catch(ex => reject(ex));
        });
    }

    getSettingsAsync() {
        return new Promise((resolve, reject) => {
            var dataAccess = new ApiDataAccess('api/application');
            dataAccess.get('/settings')
                .then(user => {
                    resolve(user);
                })
                .catch(ex => reject(ex));
        });
    }

    getFeaturesAsync() {
        return new Promise((resolve, reject) => {
            var dataAccess = new ApiDataAccess('api/settings');
            dataAccess.get('/features')
                .then(data => {
                    resolve(data.features);
                })
                .catch(ex => reject(ex));
        });
    }


}